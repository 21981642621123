import './requirements.css';
import PropTypes from "prop-types";
import Table from '../../components/table';

const getClass = (resolution) => {
  if(!resolution)
    return 'pending';
  else if(resolution.includes('Aceptado')){
    return 'approved';
  }
  return 'rejected';
};

const getDateDMY = (dateString) => {
  const date = new Date(dateString);
  return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
};

const fields = [
  {
    header: 'Fecha Postulación',
    render: ({createdAt}) => (
        <div className="__table_fields">{getDateDMY(createdAt)}</div>
    )
  },
  {
    header: 'Cliente',
    render: ({customer}) => (
        <div className="__table_fields">{customer?.name}</div>
    )
  },
  {
    header: 'Posición',
    render: ({requirement}) => (
        <div className="__table_fields">{requirement?.position}</div>
    )
  },
  {
    header: 'Estado',
    render: ({finalResolution}) => (
        <div className={'__table_fields ' + getClass(finalResolution)}>{finalResolution || 'En Proceso'}</div>
    )
  },
];


function ApplicantApplyHystory({id, label, data}) {

  if(data?.length) {
    return (
      <section className="__text-field">
        <label htmlFor={id}>
            <span>{label}</span>
        </label>
        <Table id={`${id}-table`} columns={fields} data={data} />
      </section>
    );
  }
  return null;
}

ApplicantApplyHystory.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
};

export default ApplicantApplyHystory;
