import { Link, useSearchParams } from 'react-router-dom';
import { usePagination } from '../../components/pagination';
import Page from '../../components/Page';
import SearchList from '../../components/search-list';

import { labels, endpoint } from '.';

import './ex-employees.css';

export default function List() {

  const createActions = {
    render: () => <Link to="#" className="create-actions">
      Actualizar
    </Link >
  };

  const [queryParams] = useSearchParams();

  const [query,
    pagination,
    actions,
    navigate] = usePagination(endpoint, queryParams);

  if (query.isLoading) return 'Cargando...';

  if (query.error) return 'Ocurrió un error: ' + query.error.message;

  const fields = [
    {
      header: "Nombre",
      render: ({ name, lastName, email }) => (
        <>
          <div className="__table_fields">{`${name} ${lastName}`}</div>
          <div className="__table_fields">{email}</div>
        </>
      ),
    },

    {
      header: "Rol",
      fields: ["role", "area"],
    },

    {
      header: "Ubicación",
      fields: ["city", "region"],
    },

    {
      header: "Desde",
      fields: ["dateSince"],
    },
    {
      header: "Hasta",
      fields: ["activeUntil"],
    },
  ];

  return (

    <Page title={labels.title} breadcrumb={[{ label: labels.title, link: labels.link }, { label: labels.actions.list, link: '' }]}>

      <SearchList
        addCss="width-1500"
        searchText="Buscar"
        filterValue={pagination.searchFilter}
        onSearchChange={pagination.setSearchFilter}
        onSearch={actions.searchByFilter}
        fields={fields}
        data={query.data.result}
        currentPage={pagination.page}
        pageCount={query.data.pageCount}
        hasPrevious={query.data.hasPrevious}
        hasNext={query.data.hasNext}
        onNavigateToFirstPage={navigate.toFirstPage}
        onNavigateToLastPage={navigate.toLastPage}
        onNavigateToNextPage={navigate.toNextPage}
        onNavigateToPreviousPage={navigate.toPreviousPage}
        actions={createActions}

      />

    </Page>

  );
}
