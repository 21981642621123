import { Route, Routes } from "react-router-dom";

import List from './list';

import NotFound from '../error-400';

export const endpoint = '/ex-employees';

export const labels = {
  title: 'Ex Trabajadores',
  link: '/ex-employees',
  actions: {
    list: 'Listado',
    new: ' Nuevo',
    edit: 'Editar'
  }
}

export default function IndexPage() {
  return <Routes>

    <Route exact path="/" element={<List />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
}

