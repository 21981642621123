export const editorForm = [
  {
    label: 'Nombre',
    field: 'name',
    type: 'name',
    required: true

  },
  {
    label: 'Apellido',
    field: 'lastName',
    type: 'name',
    required: true

  },
  {
    label: 'Email',
    field: 'email',
    type: 'email',
    required: true
  },
  {
    label: 'Teléfono',
    field: 'phone',
    type: 'phone',
    required: false
  }
]

export const convertListToObject = list => {
  const obj = {};

  list.forEach((value, index) => {
    obj[`${editorForm[index].field}`] = value;
  })

  return obj;
}