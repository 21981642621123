import { Route, Routes } from "react-router-dom";
import { regex } from "@23people/moonbase-validators-v3";
import EditPage from "./EditPage";
import NotFound from '../error-400';

export const endpoint = '/settings';

export const labels = {
  title: 'Configuración',
  link: '/settings',
  actions: {
    edit: 'Editar'
  }
}

export default function IndexPage() {
  return <Routes>
    <Route exact path="/" element={<EditPage endpoint={endpoint} labels={labels} nameRegex={regex.NAME} />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
}

