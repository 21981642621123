import PageTitle from '../PageTitle'
import './page.css'

export default function Page({title, cssClass = '__page', breadcrumb, size, children}) {
  
  return <section className={`${cssClass} ${size}`}>
    <PageTitle title={title} breadcrumb={breadcrumb} />
    <main>
      {children}
    </main>
   
  </section>

}