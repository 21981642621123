import React from 'react'
import PropTypes from 'prop-types'
import './table.css'
import hash from 'object-hash'


import { safeValue } from '../../lib/object-utils';

const Table = ({ columns, data, cssClass, id, keyField }) => {
  /** Render headers */
  const headerRows = columns.map((column, index) => (
    <th key={index}>{column.header}</th>
  ))

  /**
   * Render a TD
   * row: object from "data" property.
   * column: configuration object from "columns" property.
   * keyField: string or function used as key.
   */
  const renderTD = (row, column, keyField, columnIndex) => {
    const { field, fields, render, actions } = column
    let content;
    if (field && !render) {
      content = safeValue(row, field, '')
    } else if (field && render) {
      content = render(safeValue(row, field, ''))
    } else if (fields) {
      content = fields.map((field, i) => (
        <div className='__table_fields' key={i}>
          {safeValue(row, field, '')}
        </div>
      ))
    } else if (render) {
      content = render(row)
    } else if (actions) {
      content = (
        <div className='__table_actions'>
          {actions.map((action, i) => (
            <div key={i}>{action.render(row)}</div>
          ))}
        </div>
      )
      return <td data-label={`${columns[columnIndex].header}`} align="right" className="__table_actions_column" key={`${keyField}-${columnIndex}`}>{content}</td>
    }
    return <td data-label={`${columns[columnIndex].header}`} key={`${keyField}-${columnIndex}`}>{content}</td>
  }

  const generateKeyFieldFromString = (row) => {
    if (!Object.prototype.hasOwnProperty.call(row, keyField)) {
      console.error(`Row doesn't have the specified keyField: "${keyField}"`)
      return ''
    } else {
      return row[keyField]
    }
  }

  const generateDefaultKeyField = (row) => {
    if (Object.prototype.hasOwnProperty.call(row, '_id')) {
      return row._id
    } else if (Object.prototype.hasOwnProperty.call(row, 'id')) {
      return row.id
    } else {
      return hash(row)
    }
  }

  const getKeyField = (row) => {
    if (typeof keyField === 'function') {
      return keyField({ ...row })
    } else if (typeof keyField === 'string') {
      return generateKeyFieldFromString(row)
    } else {
      return generateDefaultKeyField(row)
    }
  }

  /** Render tbody rows */
  const dataRows = data.map((row) => {
    const parsedKeyField = getKeyField(row)
    return (
      <tr key={`${parsedKeyField}`}>
        {columns.map((column, columnIndex) =>
          renderTD(row, column, parsedKeyField, columnIndex)
        )}
      </tr>
    )
  })

  return (
    <section className={cssClass}>
      <table id={id} className='divide-y divide-gray-200'>
        <thead>
          <tr>{headerRows}</tr>
        </thead>
        <tbody className='divide-y divide-gray-200'>{dataRows}</tbody>
        <tfoot />
      </table>
    </section>
  )
}

Table.defaultProps = {
  cssClass: '__table'
}

Table.propTypes = {
  id: PropTypes.string.isRequired,
  cssClass: PropTypes.string,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  keyField: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
}

export default Table
