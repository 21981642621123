import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TextField from '../text-field';
import { emptyFn } from '../../lib/fn-utils';

import './search.css';

const Search = ({
	id,
	cssClass = '__text- field __search',
	tabIndex,
	value,
	label,
	secondLabel,
	message,
	maxLength,
	minLength,
	placeholder,
	onChange,
	onSearch,
	onEnter = emptyFn,
	theme,
	readOnly,
	width
}) => {

	const [searchTerm, setSearchTerm] = useState(value);
	const icon = () => {
		return (

			<svg onClick={() => { onSearch(searchTerm) }}
				style={{
					width: '1.25rem',
					height: '1.25rem',
					verticalAlign: 'middle',
					color: 'rgba(156, 163, 175, 1',
					cursor: ''
				}}
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 20 20'
				fill='currentColor'
			>
				<path
					fillRule='evenodd'
					d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
					clipRule='evenodd'
				/>
			</svg>

		);
	};

	const getPreIcon = () => {
		if (theme === 'inner-icon' || theme === 'prepend') {
			return icon;
		}
	};

	const getPostIcon = () => {
		if (theme === 'append') {
			return icon;
		}
	};

	const onChangeEv = value => {

		setSearchTerm(value);
		onChange(value);

	};

	const onEnterEv = value => {
		onEnter(value);
	}
	return (
		<TextField
			id={id}
			cssClass={`${cssClass} ${theme}`}
			tabIndex={tabIndex}
			inputType='search'
			inputmode='search'
			value={value}
			label={label}
			secondLabel={secondLabel}
			message={message}
			autocomplete='np'
			maxLength={maxLength}
			minLength={minLength}
			placeholder={placeholder}
			onChange={onChangeEv}
			pre={getPreIcon()}
			post={getPostIcon()}
			onEnter={onEnterEv}
			readOnly={readOnly}
			width={width}
		/>
	);
};

Search.defaultProps = {
	id: '',
	cssClass: '__text-field __search',
	value: '',
	required: false,
	label: '',
	secondLabel: '',
	maxLength: '',
	minLength: '',
	placeholder: '',
	validationText: '',
	message: '',
	autocomplete: true,
	enabled: true,
	onChange: () => { },
	onSearch: () => { },
	theme: '',
	readOnly: false
};

Search.propTypes = {
	id: PropTypes.string.isRequired,
	cssClass: PropTypes.string,
	tabIndex: PropTypes.string,
	value: PropTypes.string,
	required: PropTypes.bool,
	label: PropTypes.string,
	secondLabel: PropTypes.string,
	maxLength: PropTypes.string,
	minLength: PropTypes.string,
	placeholder: PropTypes.string,
	validationText: PropTypes.string,
	message: PropTypes.string,
	autocomplete: PropTypes.bool,
	enabled: PropTypes.bool,
	onChange: PropTypes.func,
	onSearch: PropTypes.func,
	theme: PropTypes.oneOf(['', 'inner-icon', 'prepend', 'append']),
	readOnly: PropTypes.bool
};

export default Search;
