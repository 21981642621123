import { regex } from '@23people/moonbase-validators-v3';

import Button from '../../../components/button';
import CardContainer from '../../../components/card-container';
import Page from '../../../components/Page';
import TextField from '../../../components/text-field';
import { Form, useForm } from '../../../components/form';

import { useState } from 'react';

import { endpoint, labels } from '.';

import Multivalue from '../../../components/multivalue';


export default function Create() {

  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [relatedTo, setRelatedTo] = useState([]);
  const [dependsOn, setDependsOn] = useState([]);

  const [valid, isValid] = useState(false);

  const validForm = !!name && !! category && valid;  //check if form is valid. Required + Valid Fields

  const [actions] = useForm(endpoint, validForm);

  const handleValidation = (value) => {
    isValid(value.valid); 
    
  };

  const handleSubmit = async () => {
    await actions.submitPost({ name, category, relatedTo, dependsOn }, 'name')
  }

  return (
    <Page title={labels.title} breadcrumb={[{ label: labels.title, link: labels.link }, {label: labels.actions.new, link: ''}]}>
      <CardContainer cssClass='__card-container __form'>
        <Form>
         
          <main>
            <TextField
              id='name'
              label='Nombre'
              secondLabel="*"
              maxLength='60'
              required={true}
              requiredText="El campo es requerido"
              value={name}
              onChange={setName}
              onValidation={handleValidation}
              pattern={regex.TEXT}
              validationText="Por favor ingrese un nombre válido"
            />
            <TextField
              id='category'
              label='Categoría'
              secondLabel="*"
              maxLength='60'
              required={true}
              requiredText="El campo es requerido"
              value={category}
              onChange={setCategory}
              onValidation={handleValidation}
              pattern={regex.TEXT}
              validationText="Por favor ingrese un nombre de categoría válido"
            />

            <Multivalue
              label="Relacionado Con"
              value={relatedTo}
              onChange={setRelatedTo}
            />

            <Multivalue
              label="Depende De"
              value={dependsOn}
              onChange={setDependsOn}
            />
          </main>
          <footer>
            <Button
            id='btnGuardar'
            value='Guardar'
            onClick={handleSubmit}
            enabled={validForm} />
          </footer>

        </Form>
      </CardContainer>
    </Page>
  );
}
