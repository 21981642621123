
import { toast } from 'react-toastify';
import Page from '../../components/Page';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apiRequest from '../../lib/api-request';
import ReferralsForm from './form';


const initialValuesState = {
  referrerName: '',
  referrerEmail: '',
  referrerPhone: '',
  referredName: '',
  referredEmail: '',
  referredPhone: '',
  referredPosition: '',
  referredLevel: '',
  referredLinkedin: '',
  referredCvId: '',
}

const initialValueErrorState = {
  referrerName: false,
  referrerEmail: false,
  referrerPhone: false,
  referredName: false,
  referredEmail: false,
  referredPhone: false,
  referredPosition: false,
  referredLevel: false,
  referredLinkedin: false,
  referredCvId: false,
}

const headers = {'Content-Type': 'application/json'};

export default function Create({ endpoint, labels }) {

  const [ values, setValues ] = useState(initialValuesState);
  const [valuesError, setValuesError] = useState(initialValueErrorState);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [referrerId, setReferrerId] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async () => {

    if(!isValid) {
      toast.error('Por favor, revise los campos');
      return;
    }

    try {
      let currReferrerId;
      let referrerResponse;
      const referredBody = {
        name: values.referrerName,
        email: values.referrerEmail,
        phone: values.referrerPhone
      };
      if(referrerId) {
        referrerResponse = await apiRequest(`${process.env.REACT_APP_API_URL}/referrers/${referrerId}`, headers, 'PUT', referredBody);
      } else if(!referrerId) {
        referrerResponse = await apiRequest(`${process.env.REACT_APP_API_URL}/referrers`, headers, 'POST', referredBody);
      }
      const data = await referrerResponse.json();
      if(data._id) {
        currReferrerId = data._id;
      }
      else {
        toast.error('Ha ocurrido un error al realizar la operación.');
        return;
      }


      const url = `${process.env.REACT_APP_API_URL}/referrals`;
      const body = {
        referrer: currReferrerId,
        name: values.referredName,
        email: values.referredEmail,
        phone: values.referredPhone,
        positionToApplicate: values.referredPosition,
        experienceLevel: values.referredLevel,
        linkedIn: values.referredLinkedin,
        cv: values.referredCvId,
      };

      const response = await apiRequest(url, headers, 'POST', body);
      const responseData = await response.json();

      if(responseData._id) {
        toast.success('El ingreso de Referido se ha realizado correctamente.');
      } else {
        toast.error('Ha ocurrido un error al ingresar el Referido.');
      }

    } catch(error) {
      toast.error('Ha ocurrido un error al ingresar el Referido.');
    }
    navigate(endpoint);
  }

  const handleEmailBlur = async (value) => {
    debugger;
    setLoading(true);
    if(!value) {
      setReferrerId('');
      setLoading(false);
      return;
    }
    try{
      const response = await apiRequest(`${process.env.REACT_APP_API_URL}/referrers/find?email=${value}`, headers, 'GET');
      const data = await response.json();
      if(data._id) {
        setReferrerId(data._id);
        setValues(v => ({ ...v, referrerName: data.name, referrerPhone: data.phone}));
        setValuesError(v => ({ ...v, referrerName: true, referrerPhone: true}));
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Page title="Ingresar Nuevo Referido" breadcrumb={[{ label: labels.title, link: labels.link }, { label: labels.actions.new, link: '' }]}>
      <ReferralsForm
      values={values}
      setValues={setValues}
      isValid={isValid}
      enableInput={true}
      handleSubmit={handleSubmit}
      toast={toast}
      valuesError={valuesError}
      setIsValid={setIsValid}
      setValuesError={setValuesError}
      loading={loading}
      setLoading={setLoading}
      handleEmailBlur={handleEmailBlur}
      />
    </Page>
  );
}
