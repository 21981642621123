import { Link, useSearchParams } from "react-router-dom";
import { useState } from "react"; 
import { usePagination } from "../../components/pagination";
import Select from "../../components/select";
import Page from "../../components/Page";
import SearchList from "../../components/search-list";
import Checkbox from "../../components/checkbox";
import Button from "../../components/button";
import "./cv-filter.css";
import CardContainer from "../../components/card-container";


export default function List({ endpoint, labels, customers }) {
  const [queryParams] = useSearchParams();
  const [query, pagination, actions, navigate] = usePagination(
    endpoint,
    queryParams,
    labels.link
  );

  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
   const handleSelectChangePosition = (option) => {
     setSelectedPosition(option);
   };

      const handleSelectChangeCustomer = (option) => {
        setSelectedCustomer(option);
      };

  if (query.isLoading) return "Cargando...";

  if (query.error) return "Ocurrió un error: " + query.error.message;

  const { result } = query.data;


       

const positionOptions = result?.map((item) => ({
  label: item.requirement.position,
  value: item.requirement._id,
}));


const customerOptions = result?.map((item) => ({
  label: customers[item.requirement.customer].name,
  value: item.requirement.customer,
}));

  const ApplicantStatus = {
    doneAccepted: "Aceptado",
    backlog: "Recepcionado",
    entry: "Inicial",
    englishTest: "Test de ingles",
    interviewedScheduledByClient: "Entrevista agendada",
    offerLetterSent: "Oferta enviada",
  };

 const handleButtonClick = () => {
 };


   <div>
     <h1>Inicio</h1>
     <Button id="myButton" value="Aprobar" onClick={handleButtonClick} />
   </div>

  const fields = [
    {
      header:"",
      render: () => (
        <span>
         <Checkbox/>
        </span>
      ),
    },
    {
      header: "Fecha/Hora",
      render: ({ workflow }) => (
        <span>
          {new Date(parseInt(workflow[0]?.date, 10))
            .toLocaleString()
            .replace(",", "")}
        </span>
      ),
    },
    {
      header: "Estado",
      render: ({ workflow }) => (
        <span>{ApplicantStatus[workflow.slice(-1)[0]?.requirementStatus]}</span>
      ),
    },
    {
      header: "Nombre",
      render: ({ applicant, requirement, cv }) => (
        <span>
          <a
            style={{ textDecoration: "underline" }}
            href={`${process.env.REACT_APP_API_URL}/cv/show/${cv.name}`}
          >
            {applicant.name} {applicant.lastname}
          </a>
          <br></br>
          <span>{requirement.position}</span>
        </span>
      ),
    },
    {
      header: "Evaluación",
      render: ({ cvAccuracity }) => <span>{cvAccuracity}%</span>,
    },

    {
      actions: [
        {
          render: ({ _id, name }) => (
            <section style={{ display: "flex", gap: "10px" }}>
              <Link to={`edit/${_id}`} className="link">
                Aprobar
              </Link>
              <span onClick={() => actions.handleDelete(_id, name)}>
                Rechazar
              </span>
            </section>
          ),
        },
      ],
    },
  ];

  return (
    <Page
      title={labels.title}
      breadcrumb={[
        { label: labels.title, link: labels.link },
        { label: labels.actions.list, link: "" },
      ]}
    >
      <CardContainer className="__cv-filter">
        <div className="__select-class">
          <Select
            id="customerSelect"
            value={selectedCustomer}
            label="Cliente"
            options={customerOptions}
            onChange={handleSelectChangeCustomer}
          />
          <Select
            id="positionSelect"
            value={selectedPosition}
            label="Posición"
            options={positionOptions}
            onChange={handleSelectChangePosition}
          />
        </div>
        <div className="__button_cv_approval">
          <Button id="myButton" value="Aprobar" onClick={handleButtonClick} />
          <Button id="myButton" value="Rechazar" onClick={handleButtonClick} />
        </div>
        <SearchList
          cssClass="__card-container __search-list"
          searchText="Buscar"
          filterValue={pagination.searchFilter}
          onSearchChange={pagination.setSearchFilter}
          onSearch={actions.searchByFilter}
          fields={fields}
          data={result}
          currentPage={pagination.page}
          pageCount={query.data.pageCount}
          hasPrevious={query.data.hasPrevious}
          hasNext={query.data.hasNext}
          onNavigateToFirstPage={navigate.toFirstPage}
          onNavigateToLastPage={navigate.toLastPage}
          onNavigateToNextPage={navigate.toNextPage}
          onNavigateToPreviousPage={navigate.toPreviousPage}
        />
      </CardContainer>
    </Page>
  );
}
