import { useEffect, useState } from 'react';

export default function useGoogleLogin(clientId, buttonId, locale = 'es_CL') {
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);

  useEffect(() => {
    function handleCredentialResponse(response) {

      const decodedToken = window.atob(response.credential.split('.')[1]);
      const parsedToken = JSON.parse(decodedToken);

      setEmail(parsedToken.email);
      setEmailVerified(parsedToken.email_verified);
      setToken(response.credential);
    }

    window.google.accounts.id.initialize({
      client_id: clientId,
      callback: handleCredentialResponse,
    });

    window.google.accounts.id.renderButton(document.getElementById(buttonId), {
      locale,
      theme: 'outline',
      size: 'large',
      shape: 'pill'
    });

    return () => {
      setToken('');
    };
  }, [clientId, buttonId, locale]);

  return { token, email, emailVerified};
}
