import { HiChevronRight } from 'react-icons/hi'
import { Link } from 'react-router-dom';

import './page-title.css'
export default function PageTitle({ title, breadcrumb, breadcrumb2 }) {
  
  return <header className='__page-title'>
    <ul>
      {breadcrumb?.map((el, index) => {
        return el.label ? <li key={el.label}>
          <Link to={el?.link}>{el?.label}</Link>
          <HiChevronRight size={16} />
        </li> : 
          <li key={el}>
            {el}
            <HiChevronRight size={16} />
          </li>
  
      })}

    </ul>
    <h1>{title}</h1>
  </header>;
}