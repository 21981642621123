import CardContainer from '../card-container';

import { emptyFn } from '../../lib/fn-utils';

import './steps.css';

const StepSeparator = () => {
  return (
    <div>
      <svg viewBox='0 0 22 80' fill='none' preserveAspectRatio='none'>
        <path
          d='M0 -2L20 40L0 82'
          vectorEffect='non-scaling-stroke'
          stroke='currentColor'
          strokeLinejoin='round'
        ></path>
      </svg>
    </div>
  );
};

const StatusIcon = (state, index) => {
  if (state === 'completed') return <span className='completed'>
    <svg viewBox='0 0 24 24' fill='currentColor' aria-hidden='true'>
      <path
        fillRule='evenodd'
        d='M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z'
        clipRule='evenodd'
      ></path>
    </svg>
  </span>;

  let position = index < 9 ? `0${index + 1}` : index + 1;

  return <span className={state}>
    <span>{position}</span>
  </span>

}

function Steps({
  cssClass = '__steps',
  labels = [
    { name: 'Step 1' },
    { name: 'Step 2' }
  ],
  onClick = emptyFn
}) {
  return (
    <section className={`${cssClass}`}>
      <CardContainer>
        <nav>
          <ol>
            {labels.map((label, index) => {
              return (
                <li key={label.name}>
                  <a key={index} href='#step' role="button" onClick={() => { onClick(label.name)}}>
                    {StatusIcon(label.state, index)}
                    {label.name}
                  </a>
                  {index < labels.length - 1 ? <StepSeparator /> : ''}
                 
                </li>
              );

            } )}
           
          </ol>
        </nav>
      </CardContainer>
    </section>
  );
}

export default Steps;
