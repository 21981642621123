import { Link, useSearchParams } from 'react-router-dom';
import { usePagination } from '../../components/pagination';

import Page from '../../components/Page';

import SearchList from '../../components/search-list';

const fields = [
  {
    header: 'Referido',
    field: 'name',
  },
  {
    header: 'LinkedIn',
    field: 'linkedIn',
  },
  {
    header: 'Posición',
    field: 'positionToApplicate',
  },
  {
    header: 'CV',
    render: ({ cv }) => (
      <div className="__table_fields">
        { cv?.name ? <Link target="_blank" className="link" to={`/cv/show/${cv?.name}`}>Ver</Link>: '--' }
      </div>
    )
  },
  {
    header: 'Referido Por',
    render: ({ referrer }) => (
      <div className="__table_fields">{referrer?.name}</div>
    )
  },
  {
    header: "Acciones",
    actions: [
      {
        render: ({ _id }) => (
          <section style={{ display: 'flex', gap: '10px' }}>
            <Link to={`edit/${_id}`} className="link">
              Editar
            </Link>
          </section>
        ),
      }
    ]
  }
];

const createActions = {
  render: () => <Link to="new" className="create-actions">
    Nuevo
  </Link >
};

const endpoint = '/referrals';

export default function List() {
  const [queryParams] = useSearchParams();

  const [query,
    pagination,
    actions,
    navigate] = usePagination(endpoint, queryParams);

  if (query.isLoading) return 'Loading...';

  if (query.error) return 'An error has occurred: ' + query.error.message;

  return (

    <Page title="Referidos" breadcrumb={['Referidos', 'Listado']}>

      <SearchList
        searchText="Buscar"
        filterValue={pagination.searchFilter}
        onSearchChange={pagination.setSearchFilter}
        onSearch={actions.searchByFilter}
        fields={fields}
        data={query.data.result}
        currentPage={pagination.page}
        pageCount={query.data.pageCount}
        hasPrevious={query.data.hasPrevious}
        hasNext={query.data.hasNext}
        onNavigateToFirstPage={navigate.toFirstPage}
        onNavigateToLastPage={navigate.toLastPage}
        onNavigateToNextPage={navigate.toNextPage}
        onNavigateToPreviousPage={navigate.toPreviousPage}
        actions={createActions}
      />

    </Page>

  );
}
