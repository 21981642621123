
import { HiChevronDoubleLeft, HiChevronDoubleRight, HiChevronLeft, HiChevronRight } from 'react-icons/hi';

import CardContainer from '../card-container';

import './search-list.css';
import Search from '../search';
import Button from '../button';
import Table from '../table';

export default function SearchList({
  id,
  cssClass = '__card-container __search-list',
  addCss = '',
  searchText,
  filterValue,
  fields,
  data,
  currentPage,
  pageCount,
  hasPrevious,
  hasNext,
  onSearchChange,
  onSearch,
  actions,
  onNavigateToFirstPage,
  onNavigateToNextPage,
  onNavigateToPreviousPage,
  onNavigateToLastPage

}) {

  return <CardContainer cssClass={`${cssClass} ${addCss}`}>

    <header>
      <section>
        <Search id={`${id}-search`} theme="prepend" placeholder={searchText || ''} value={filterValue || ''}
          onChange={onSearchChange} onEnter={onSearch} />
        <Button id={`${id}-searchButton`}
          value={searchText}
          onClick={onSearch}
        />
      </section>

      <section>
        {actions ? actions.render() : ''}
      </section>

    </header>
    <main>

      <Table id={`${id}-table`} columns={fields} data={data} />

    </main>
    <footer>
      <span>{`Página ${currentPage || 1} de  ${pageCount}`}</span>
      <section className='navigation-buttons'>
        <Button id="btnFirst"
          value={<HiChevronDoubleLeft />}
          enabled={hasPrevious}
          onClick={onNavigateToFirstPage}
        />
        <Button id="btnBefore"
          value={<HiChevronLeft />}
          enabled={hasPrevious}
          onClick={onNavigateToPreviousPage}

        />
        <Button id="btnNext"
          value={<HiChevronRight />}
          enabled={hasNext}
          onClick={onNavigateToNextPage}
        />
        <Button id="btnLast"
          value={<HiChevronDoubleRight />}
          enabled={hasNext}
          onClick={onNavigateToLastPage}
        />

      </section>
    </footer>

  </CardContainer>

}