import { Route, Routes, useSearchParams } from "react-router-dom";

import { usePagination } from "../../components/pagination";

import List from "./list";

import NotFound from "../error-400";

export const endpoint = "/cv-filter";

export const labels = {
  title: "Filtro CV",
  link: "/cv-filter",
  actions: {
    list: "Listado",
    new: " Nuevo",
    edit: "Editar",
  },
};

const endpoint_customer = "/customers";
function PullCustomers() {
  const [queryParams] = useSearchParams();
  const [query, pagination, actions, navigate] = usePagination(
    endpoint_customer,
    queryParams,
    endpoint_customer
  );
  if (query.isLoading) return "Cargando...";

  if (query.error) return "Ocurrió un error: " + query.error.message;

  const { result } = query.data;

  const customers = result.reduce((obj, item) => {
    obj[item._id] = item;
    return obj;
  }, {});

  return customers;
}

export default function IndexPage() {
  const customers = PullCustomers();
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <List endpoint={endpoint} labels={labels} customers={customers} />
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}