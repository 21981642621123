import { useState } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useNavigate, createSearchParams } from 'react-router-dom';
import apiRequest from '../../lib/api-request';

export const fetchPage = async (url, page, size, filter, position, customer, sort) => {

  const params = {};

  if (page) {
    params['page'] = page;
  }

  if (size) {
    params['size'] = size;
  }
  if (sort) {
    params['sort'] = sort;
  }

  if (filter) {
    params['filter'] = filter;
  }

  if(position){
    params['position'] = position;
  }

  if(customer){
    params['customer'] = customer;
  }

  let baseUrl = `${process.env.REACT_APP_API_URL}${url}`;

  const searchParams = new URLSearchParams(params).toString();

  if (searchParams) {
    baseUrl = `${baseUrl}?${searchParams}`;
  }

  const result = await apiRequest(baseUrl)
  const json = await result.json();

  return json;
};

export const paramsFromSearch = (queryParams) => {
  const params = {};

  if (queryParams.get('page')) {
    params['page'] = queryParams.get('page');
  }

  if (queryParams.get('size')) {
    params['size'] = queryParams.get('size');
  }
  if (queryParams.get('sort')) {
    params['sort'] = queryParams.get('sort');
  }

  if (queryParams.get('filter')) {
    params['filter'] = queryParams.get('filter');
  }

  if (queryParams.get('position')) {
    params['position'] = queryParams.get('position');
  }

  if (queryParams.get('customer')) {
    params['customer'] = queryParams.get('customer');
  }
  return params;
};

export function usePaginationCustom(endpoint, queryParams, link = endpoint) {

  const queryClient = useQueryClient();

  const [page, setPage] = useState(queryParams.get('page'));
  const [size, setSize] = useState(queryParams.get('size'));
  const [filter, setFilter] = useState(queryParams.get('filter'));

  const [customer, setCustomer] = useState(queryParams.get('customer'));
  const [position, setPosition] = useState(queryParams.get('position'));

  const [searchFilter, setSearchFilter] = useState(queryParams.get('filter'));

  const { data, isLoading, error } = useQuery(
    [endpoint, page, size, filter, position, customer],
    () => fetchPage(endpoint, page || '1', size || '10', filter || '', position || '' , customer || ''),
    { keepPreviousData: true }
  );

  const navigation = useNavigate();

  const toNextPage = () => {
    const params = paramsFromSearch(queryParams);

    let newPage = '2';

    if (params.page) {
      newPage = (parseInt(params.page, 10) + 1).toString();
    }

    params.page = newPage;

    const url = `${link}?${createSearchParams(params)}`;

    navigation(url);
    setPage(newPage);
  };

  const toPreviousPage = () => {
    const params = paramsFromSearch(queryParams);

    let newPage = '1';

    if (params.page) {
      newPage = parseInt(params.page, 10) - 1;
    }

    params.page = (newPage || 1).toString();

    const url = `${link}?${createSearchParams(params)}`;

    navigation(url);
    setPage(newPage);
  };

  const toFirstPage = () => {
    const params = paramsFromSearch(queryParams);

    params.page = '1';

    const url = `${link}?${createSearchParams(params)}`;

    navigation(url);
    setPage('1');

  };

  const toLastPage = () => {
    const params = paramsFromSearch(queryParams);

    params.page = data.pageCount;

    const url = `${link}?${createSearchParams(params)}`;

    navigation(url);
    setPage(data.pageCount);

  };

  const changeSize = (value) => {
    const params = paramsFromSearch(queryParams);

    params.size = value;

    const url = `${link}?${createSearchParams(params)}`;
    navigation(url);
    setSize(value);
  };

  const searchByFilter = (name, value) => {
    const params = paramsFromSearch(queryParams);
    params.page = '1';

    if (typeof value === 'string') {
      params[name] = value;
    } else {
      params.filter = searchFilter || '';
    }

    const url = `${link}?${createSearchParams(params)}`;

    navigation(url);
    setPage('1');

    if(name === 'position'){
      setPosition(value);
    }else if(name === 'customer'){
      setCustomer(value);
    }else{
      setFilter(params.filter);
    }
    //setFilter(params.filter);

  }

  const handleDelete = async (id, name, link = endpoint) => {

    const url = `${process.env.REACT_APP_API_URL}${endpoint}/${id}`;

    const headers = {
      'Content-Type': 'application/json',
    }

    const response = await apiRequest(url, headers, 'DELETE');

    if (response.ok) {
      //invalidate query
      queryClient.invalidateQueries(endpoint);

      toast.success(`${name} eliminado con exitosamente.`);
      navigation(link)

    } else {
      toast.error(`Error al eliminar ${name}`);
    }

  };

  const navigate = {
    toFirstPage,
    toLastPage,
    toNextPage,
    toPreviousPage
  }

  const actions = {
    changeSize,
    searchByFilter,
    handleDelete
  }

  const query = {
    data,
    isLoading,
    error
  }

  const pagination = {
    page,
    size,
    searchFilter,
    setSearchFilter,
  }

  return [
    query,
    pagination,
    actions,
    navigate

  ];
}
