import { usePaginationCustom } from "../../components/custom-pagination";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import Page from "../../components/Page";
import SearchList from "../../components/search-list";
import SelectRest from "../../components/select-rest";
import { api } from "../../api";


export default function List({ endpoint, labels }) {
  const [queryParams] = useSearchParams();
  const [customer, setCustomer] = useState('');
  const [position, setPosition] = useState('');

  const [query, pagination, actions, navigate] = usePaginationCustom(
    endpoint,
    queryParams,
    labels.link
  );

  const onChangeCustomer = (e) => {
    setCustomer(e)
    actions.searchByFilter('customer', e);
  }

  const onChangePosition = (e) => {
    setPosition(e)
    actions.searchByFilter('position', e);
  }

  if (query.isLoading) return "Cargando...";

  if (query.error) return "Ocurrió un error: " + query.error.message;

  const { result } = query.data;

  const ApplicantStatus = {
    doneAccepted: "Aceptado",
    backlog: "Recepcionado",
    entry: "Inicial",
    englishTest: "Test de ingles",
    interviewedScheduledByClient: "Entrevista agendada",
    offerLetterSent: "Oferta enviada",
  };

  const fields = [
    {
      header: "Nombre",
      render: ({ applicant }) => (
        <span>
          {applicant.name} {applicant.lastname}
        </span>
      ),
    },
    {
      header: "Estado",
      render: ({ workflow }) => (
        <span>{ApplicantStatus[workflow?.slice(-1)[0]?.requirementStatus]}</span>
      ),
    },
    {
      header: "CV",
      render: ({ cv }) => (
        <a
          style={{ textDecoration: "underline" }}
          href={`${process.env.REACT_APP_API_URL}/cv/show/${cv.name}`}
        >
          {cv.name}
        </a>
      ),
    },
    {
      header: "Evaluación CV",
      render: ({ cvAccuracity }) => <span>{cvAccuracity}%</span>,
    },
    {
      header: "Posición",
      render: ({ requirement }) => <span>{requirement.position}</span>,
    },
  ];

  return (
    <Page
      title={labels.title}
      breadcrumb={[
        { label: labels.title, link: labels.link },
        { label: labels.actions.list, link: "" },
      ]}
    >

      <SelectRest
        id='customer'
        label='Cliente'
        required={true}
        requiredText="El campo es requerido"
        secondLabel='*'
        placeholder='Equifax, ACHS'
        onChange={onChangeCustomer}
        value={customer}
        valueField='_id'
        labelField='name'
        endpoint={api.customers}
        width='20%'
      />

      <SelectRest
        id='position'
        label='Posicion'
        required={true}
        requiredText="El campo es requerido"
        secondLabel='*'
        placeholder='Senior Software Engineer, Software Engineer'
        onChange={onChangePosition}
        value={position}
        valueField='name'
        labelField='name'
        endpoint={api.positions}
        width='20%'
      />

      <br/>

      <SearchList
        searchText="Buscar"
        filterValue={pagination.searchFilter}
        onSearchChange={pagination.setSearchFilter}
        onSearch={actions.searchByFilter}
        fields={fields}
        data={result}
        currentPage={pagination.page}
        pageCount={query.data.pageCount}
        hasPrevious={query.data.hasPrevious}
        hasNext={query.data.hasNext}
        onNavigateToFirstPage={navigate.toFirstPage}
        onNavigateToLastPage={navigate.toLastPage}
        onNavigateToNextPage={navigate.toNextPage}
        onNavigateToPreviousPage={navigate.toPreviousPage}
      />
    </Page>    
  );
}
