import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom"
import { useQuery } from '@tanstack/react-query';

import { regex } from '@23people/moonbase-validators-v3';

import Button from '../button';
import CardContainer from '../card-container';
import Page from '../Page';
import TextField from '../text-field';
import { Form, useForm } from '../form';
import apiRequest from '../../lib/api-request';

export const fetchData = async (url) => {

  let baseUrl = `${process.env.REACT_APP_API_URL}${url}`;

  const result = await apiRequest(baseUrl);
  if (!result.ok) {
    throw new Error('El resgistro no existe')
  }
  const json = await result.json();

  return json;
};

export default function EditPage({ endpoint, labels, nameRegex = regex.TEXT }) {

  const { id } = useParams();
  const [name, setName] = useState();
  const [valid, isValid] = useState(false);

  const validForm = !!name && valid;  //check if form is valid. Required + Valid Fields

  const [actions] = useForm(endpoint, validForm, labels.link);

  const { data, isError, isLoading, status } = useQuery(
    [`${endpoint}/${id}`],
    () => fetchData(`${endpoint}/${id}`)
  );

  useEffect(() => {
    if (status === 'success') {
      setName(data.name);
    }
  }, [data, status]);

  if (isLoading) return 'Cargando...';

  if (isError) return 'Ocurrió un error';

  const handleValidation = (value) => {
    isValid(value.valid);

  };

  const handleSubmit = async () => {
    await actions.submitUpdate(id, { name }, 'name')
  }

  return (
    <Page title={labels.title} breadcrumb={[{ label: labels.title, link: labels.link }, { label: labels.actions.edit, link: '' }]}>
      <CardContainer cssClass='__card-container __form'>
        <Form>
          <main>
            {isError && <span style={{ color: 'red' }}>El registro no existe</span>}

            <TextField
              id='name'
              label='Nombre'
              secondLabel="*"
              maxLength='60'
              required={true}
              requiredText="El campo es requerido"
              value={name}
              onChange={setName}
              onValidation={handleValidation}
              pattern={nameRegex}
              enabled={true}
              validationText="Por favor ingrese un nombre válido"
            />

          </main>
          <footer>
            <Button
              id='btnGuardar'
              value='Guardar'
              onClick={handleSubmit}
              enabled={validForm} />
          </footer>

        </Form>
      </CardContainer>
    </Page>
  );
}
