import './object-table.css'

const ObjectTable = ({ json, cssClass = '__object-table', id, label }) => {

  if (!json) return "";

  const translatedText = {
    todo: "Por Hacer",
    preselectionInProgress: "Preselección en Proceso",
    preselectionDone: "Preselección Listo",
    selectionInProgress: "Selección Recibiendo Postulaciones",
    selectionSentToClient: "Selección Candidato Enviado al Cliente",
    totalTimeElapsed: "Tiempo total transcurrido",
  }

  const dataRows = Object.keys(json);


  return (
    <section className={cssClass}>
      <table id={id} className='divide-y divide-gray-200'>
        <thead>
          <tr>
            <th>{label}</th>
            <th></th>
          </tr>
        </thead>
        <tbody className='divide-y divide-gray-200'>{dataRows.map(key => {
          return <tr>
            <td>{translatedText[key]}</td>
            <td>{json[key]}</td>
          </tr>
        })}</tbody>
        <tfoot />
      </table>
    </section>
  )
}

export default ObjectTable;