export const safeObject = obj => {
  const copyObj = JSON.parse(JSON.stringify(obj));
  delete copyObj.password;
  delete copyObj.confirmPassword;
  delete copyObj.newPassword;
  delete copyObj.oldPassword;

  return copyObj;
}

export const safeValue = (obj, path, defaultValue = '') => {
  try {
    const paths = path.split('.');
    let current = obj;

    for (const element of paths) {
      if (current[element] === undefined) {
        return defaultValue;
      }

      current = current[element];
    }

    return current;
  } catch (error) {
    return defaultValue;
  }
};

export const unescapeHTML = str =>
  str.replace(
    /&amp;|&lt;|&gt;|&#39;|&quot;/g,
    tag =>
    ({
      '&amp;': '&',
      '&lt;': '<',
      '&gt;': '>',
      '&#39;': "'",
      '&quot;': '"'
    }[tag] || tag)
  );
