import { Route, Routes } from "react-router-dom";

import { regex } from "@23people/moonbase-validators-v3";
import Create from "./create";
import Edit from "./edit";
import List from "./list";

import NotFound from '../../error-400';

export const endpoint = '/benefits-type';

export const labels = {
  title: 'Tipo de Beneficios',
  link: '/benefits-type',
  actions: {
    list: 'Listado',
    new: ' Nuevo',
    edit: 'Editar'
  }
}

export default function IndexPage() {
  return <Routes>
    <Route exact path="/new" element={<Create endpoint={endpoint} labels={labels} />} />
    <Route exact path="/edit/:id" element={<Edit endpoint={endpoint} labels={labels} nameRegex={regex.NAME} />} />
    <Route exact path="/" element={<List/>} />
    <Route path="*" element={<NotFound />} />
  </Routes>
}

