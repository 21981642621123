import { redirect } from "react-router-dom";

const apiRequest = async (url, headers = {}, method = 'GET', body = undefined, stringify = true) => {

    try {

        const requestProperties = {
            headers,
            method,
            credentials: 'include',
            mode: 'cors',
            body: stringify && body ? JSON.stringify(body) : body
        }

        const response = await fetch(url, requestProperties);

        if (response.status === 401) {
            redirect('/login');
        }

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(errorText);
        }

        return response;
    } catch (error) {
        if (error.statusCode === 401) {
            redirect('/login');
        } else {
            throw new Error(error);
        }

    }

}

export default apiRequest;