import './error.css';

export default function NotAuthorized() {
  return (
    <div className='not-authorized'>

      <img src="/img/403.svg" alt="No Permitido" />

    </div>
  );
}