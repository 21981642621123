import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
// import { redirect } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';

import Customers from './pages/customers';
import Requirements from './pages/requirements';
import NotFound from './pages/error-400';
import Header from './components/header';
import Menu from './components/menu';
import Home from './pages/home';
import Login from './pages/login';
import Applications from './pages/applications';
import CvFilter from './pages/cv-filter';
import Benefits from './pages/cruds/benefits';
import BenefitsType from './pages/cruds/benefits-type';
import Countries from './pages/cruds/countries';
import EnglishLevels from './pages/cruds/english-levels';
import PositionLevels from './pages/cruds/position-levels';
import RequirementTypes from './pages/cruds/requirement-types';
import Skills from './pages/cruds/skills';
import WorkingConditions from './pages/cruds/working-conditions';
import Currencies from './pages/cruds/currencies';
import Platforms from './pages/cruds/platforms';
import Employees from './pages/employees';
import ExEmployees from './pages/ex-employees';
import Users from './pages/users';
import Settings from './pages/settings';
import Cv from './pages/cv';

import './app-layout.css';

import { AuthProvider } from './context/AuthProvider';
import ProtectedRoute from './components/protected-route';
import NotAuthorized from './pages/error-403';

import { ROLES } from './pages/users';
import Referrals from './pages/referrals/index';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      retry: false,
      retryDelay: false,
    },
  },
});

function AppLayout() {
  return (
    <div className='app'>
      <input type='checkbox' id='sidebartoggler' />
      <Header />
      <ToastContainer />
      <Menu />

      <main>
        <Outlet />
      </main>
    </div>
  );
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Router>

          <Routes>
            <Route path='/login' element={<Login />} />

            <Route path='/' element={<AppLayout />}>

              <Route element={<ProtectedRoute allowedRoles={[ROLES.ADMIN]} />}>
                <Route path='/benefits/*' element={<Benefits />} />
                <Route path='/benefits-type/*' element={<BenefitsType />} />
                <Route path='/countries/*' element={<Countries />} />
                <Route path='/english-levels/*' element={<EnglishLevels />} />
                <Route path='/position-levels/*' element={<PositionLevels />} />
                <Route path='/requirement-types/*' element={<RequirementTypes />} />
                <Route path='/skills/*' element={<Skills />} />
                <Route path='/working-conditions/*' element={<WorkingConditions />} />
                <Route path='/currencies/*' element={<Currencies />} />
                <Route path='/platforms/*' element={<Platforms />} />
                <Route path='/users/*' element={<Users />} />
                <Route path='/settings/*' element={<Settings />} />
              </Route>

              <Route element={<ProtectedRoute allowedRoles={[ROLES.ADMIN, ROLES.HR]} />}>
                <Route path='/employees/*' element={<Employees />} />
                <Route path='/ex-employees/*' element={<ExEmployees />} />
              </Route>

              <Route element={<ProtectedRoute allowedRoles={[ROLES.ADMIN, ROLES.COMMERCIAL]} />}>
                <Route path='/customers/*' element={<Customers />} />
                <Route path='/requirements/*' element={<Requirements />} />
              </Route>

              <Route element={<ProtectedRoute allowedRoles={[ROLES.ADMIN, ROLES.COMMERCIAL, ROLES.RECRUITER]} />}>
                <Route path='/cv/show/:fileName' element={<Cv />} />
                <Route path='/applications/*' element={<Applications />} />
                <Route path='/referrals/*' element={<Referrals />} />
                <Route path='/cv-filter/*' element={<CvFilter />} />
              </Route>

              <Route element={<ProtectedRoute allowedRoles={[ROLES.ADMIN, ROLES.COMMERCIAL, ROLES.RECRUITER, ROLES.HR]} />}>
                <Route path='/unauthorized' element={<NotAuthorized />} />
                <Route path='/' exact={true} element={<Home />} />

              </Route>

            </Route>

            <Route path='*' element={<NotFound />} />
          </Routes>

        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
