import useGoogleLogin from './useGoogleLogin';
import apiRequest from '../../lib/api-request';
import { useQuery } from '@tanstack/react-query';

const login = async (token, email) => {

  try {
    let baseUrl = `${process.env.REACT_APP_API_URL}/auth/login`;

    const result = await apiRequest(baseUrl, { 'Content-Type': 'application/json' }, 'POST', { token, email });

    if (!result.ok) {
      throw new Error('Error al iniciar sesión');
    }

    if (result.status === 401) {

      throw new Error('No tiene permisos para iniciar sesión');
    }
    const json = await result.json();

    return json;
  } catch (error) {
    throw error;
  }

}

export default function GoogleLoginButton({ clientId, onValidLogin = () => { } }) {
  const { token, email } = useGoogleLogin(clientId, 'googleLoginButton');

  const { isError } = useQuery(['login', token], () => login(token, email), {
    enabled: !!token, //query executes only when token is set
    onSuccess: (data) => {
      onValidLogin({ valid: true, user: data.user })
    }
  });

  return (
    <>
      <div id='googleLoginButton'></div>
      {isError ? <span>No posee credenciales para la aplicación. Por favor contactar con los administradores.</span> : ''}
    </>
  );
}
