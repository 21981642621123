import { useEffect, useRef, useState } from 'react';
import Button from '../button';
import CardContainer from '../card-container';
import './logo-picker.css';

const defaultImg = '/img/logo-ph-400.png';
const defaultBkgColor = '#ffffff';
const defaultTxtColor = '#000000';

const emptyFn = () => { };

const LogoPicker = ({
  id,
  cssClass = '__logo-picker',
  label,
  secondLabel,
  src = defaultImg,
  backgroundColor = defaultBkgColor,
  logoBackgroundColor = defaultBkgColor,
  textColor = defaultTxtColor,
  enabled = true,
  onChange = emptyFn,
  onBackgroundColorChange = emptyFn,
  onLogoBackgroundColorChange = emptyFn,
  onTextColorChange = emptyFn,
  onValidation = emptyFn
}) => {

  const [imgSrc, setImgSrc] = useState(src);
  const [logoBckColor, setLogoBckColor] = useState(logoBackgroundColor);
  const [bckColor, setBckColor] = useState(backgroundColor);
  
  const [txtColor, setTxtColor] = useState(textColor);

  useEffect(() => {
    return () => {
      console.log(`revokeUrl: ${imgSrc}`);
      URL.revokeObjectURL(imgSrc);
    };
  }, [imgSrc]);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  useEffect(() => {
    setLogoBckColor(logoBackgroundColor);
  }, [logoBackgroundColor]);

  useEffect(() => {
    setBckColor(backgroundColor);
  }, [backgroundColor]);



  useEffect(() => {
    setTxtColor(textColor);
  }, [textColor]);

  const fileInput = useRef(null);

  const selectFile = () => {
    fileInput.current.click();
  };

  const handleFileChange = e => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setImgSrc(objectUrl);
    onChange(objectUrl, e.target.files[0]);
    const isValid = e.target.files[0].name !== '';
    onValidation({ field: id, valid: isValid });
  };

  const changeBackgroundColor = e => {
    setBckColor(e.target.value);
    onBackgroundColorChange(e.target.value);
    const isValid = e.target.value !== defaultBkgColor;
    onValidation({ field: id, valid: isValid });
  };


  const changeLogoBackgroundColor = e => {
    setLogoBckColor(e.target.value);
    onLogoBackgroundColorChange(e.target.value);
    const isValid = e.target.value !== defaultBkgColor;
    onValidation({ field: id, valid: isValid });
  };

  const changeTextColor = e => {
    setTxtColor(e.target.value);
    onTextColorChange(e.target.value);
    const isValid = e.target.value !== defaultTxtColor;
    onValidation({ field: id, valid: isValid });
  };
  

  return (
    <section className={cssClass}>
      <header>
        <label htmlFor={id}>
          <span>{label}</span>
          <span>{secondLabel}</span>
        </label>
      </header>

      <main>
        <section style={{ backgroundColor: logoBckColor }}>
          <img src={imgSrc} onChange={setImgSrc} alt='logo' />
        </section>
        <section>
          <section>
            <Button id='btnSeleccionar' value='Seleccionar' onClick={selectFile} enabled={enabled} />
            {/* this component is hidden to use a common button */}
            <input type='file' accept='image/*' onChange={handleFileChange} ref={fileInput} />
            <input
              id='logo-bck-color-picker'
              type='color'
              value={logoBckColor}
              onChange={changeLogoBackgroundColor}
            />
            <label htmlFor='logo-bck-color-picker'>Fondo Logo</label>
            <input
              id='bck-color-picker'
              type='color'
              value={bckColor}
              onChange={changeBackgroundColor}
            />
            <label htmlFor='bck-color-picker'>Color Fondo</label>
            <input
              id='text-color-picker'
              type='color'
              value={txtColor}
              onChange={changeTextColor}
            />
            <label htmlFor='text-color-picker'>Color Texto</label>
          </section>
  
        </section>
      </main>
      <footer>
        <CardContainer cssClass='__card-container __company-card' backgroundColor={bckColor} >
          <img src={imgSrc} onChange={setImgSrc} alt='logo' />
          <section className='labels'>
            <header style={{color: txtColor}}>Red Pill Inc</header>
            <main style={{ color: txtColor }} >Senior FullStack Developer Angular/Java/Spring</main>
            <footer style={{ color: txtColor }}>Chile | Remoto - $1.500.000 - $2.000.000 CLP</footer>
          </section>
        </CardContainer>
      </footer>
    </section>
  );
};

export default LogoPicker;
