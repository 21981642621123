import { api } from '../../../api';
import { Form } from '../../../components/form';
import { labels, endpoint } from '.';
import { regex } from '@23people/moonbase-validators-v3';
import { useState } from 'react';
import Button from '../../../components/button';
import CardContainer from '../../../components/card-container';
import Page from '../../../components/Page';
import SelectRest from '../../../components/select-rest';
import TextArea from '../../../components/text-area';
import TextField from '../../../components/text-field';
import { useForm } from '../../../components/form';


export default function Create() {

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [benefitType, setBenefitType] = useState('');

  const handleSubmit = async () => {
    await actions.submitPost({ name, description, benefitType }, 'name');
  }

  const validForm = () => {
    return !!name && !!description && !!benefitType;
  }

  const [ actions ] = useForm(endpoint, validForm);

  return (
    <Page title={labels.title} breadcrumb={[{ label: labels.title, link: labels.link }, {label: labels.actions.new, link: ''}]}>
      <CardContainer cssClass='__card-container __form'>
        <Form>
          <main>
            
            <TextField
              id='name'
              label='Nombre'
              placeholder='Ej: Seguro de vida'
              secondLabel="*"
              maxLength='60'
              required={true}
              requiredText="El campo es requerido"
              value={name}
              onChange={setName}
              pattern={regex.TEXT}
              validationText="Por favor ingrese un nombre válido"
            />

            <TextArea
              id='description'
              label='Descripción'
              secondLabel="*"
              maxLength='400'
              placeholder={'Ej: Seguro de vida para todos los empleados'}
              required={true}
              value={description}
              onChange={setDescription}
              validationText={'Por favor ingrese una descripción válida'}
            />

            <SelectRest
              id='benefit-type'
              label='Tipo de beneficio'
              placeholder='seleccione tipo de beneficio'
              required={true}
              value={benefitType}
              onChange={setBenefitType}
              endpoint={api.benefitsType}
              labelField='name'
              valueField='_id'
            />

          </main>

          <footer>
            <Button
              id='btnGuardar'
              value='Guardar'
              onClick={handleSubmit}
              enabled={validForm} 
            />
          </footer>
        </Form>
      </CardContainer>
    </Page>
  )
}