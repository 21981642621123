import { convertListToObject, editorForm } from './commons';
import { endpoint, labels } from '.';
import { Form, useForm } from '../../components/form';
import { regex } from '@23people/moonbase-validators-v3';
import { useState } from 'react';
import AWSLogoPicker from '../../components/aws-logo-picker';
import Button from '../../components/button';
import CardContainer from '../../components/card-container';
import EditableTable from '../../components/editable-table';
import Multivalue from '../../components/multivalue';
import Page from '../../components/Page';
import TextArea from '../../components/text-area';
import TextField from '../../components/text-field';

const signedUrl = `${process.env.REACT_APP_API_URL}/files/signing`;
export default function Create() {

  const [name, setName] = useState('');
  const [teams, setTeams] = useState([]);
  const [managers, setManagers] = useState([]);
  const [about, setAbout] = useState('');

  const [file, setFile] = useState();
  const [etag, setEtag] = useState();
  const [logo, setLogo] = useState('/img/logo-ph-400.png');
  const [logoBckColor, setLogoBckColor] = useState('white');
  const [bckColor, setBckColor] = useState('white');
  const [textColor, setTextColor] = useState('black');

  const [valid, isValid] = useState(false);

  const validForm = !!name && valid;  //check if form is valid. Required + Valid Fields

  const [actions] = useForm(endpoint, validForm);

  const handleValidation = (value) => {
    isValid(value.valid);

  };

  const changeFile = ({ filename, etag }) => {
    setFile(filename);
    setEtag(etag);

    const logoUrl = `${process.env.REACT_APP_API_URL}/files/show/${filename}`;

    setLogo(logoUrl);

  }

  const changeSrc = src => {
    setLogo(src);
  }

  const handleSubmit = async () => {
    await actions.submitPost({
      name,
      about,
      teams,
      brand: {
        logo,
        textColor,
        logoBackgroundColor: logoBckColor,
        backgroundColor: bckColor,
      },
      managers
    }, 'name')
  }


  const handleTableChange = (newValue, event) => {
  
    if(event === 'add'){
      const obj = convertListToObject(newValue);
      setManagers([...managers, obj]);
    }else if(event === 'remove'){
      const filterManagers = managers.filter(manager => manager.email !== newValue.email);
      setManagers(filterManagers)
    }
  }

  return (
    <Page title={labels.title} breadcrumb={[{ label: labels.title, link: labels.link }, { label: labels.actions.new, link: '' }]}>
      <CardContainer cssClass='__card-container __form'>
        <Form>

          <main>
            <TextField
              id='name'
              label='Nombre'
              secondLabel="*"
              maxLength='60'
              required={true}
              requiredText="El campo es requerido"
              value={name}
              onChange={setName}
              onValidation={handleValidation}
              pattern={regex.TEXT}
              validationText="Por favor ingrese un nombre válido"
            />

            <TextArea
              id='about'
              label='Acerca De'
              value={about}
              onChange={setAbout}
              maxLength='1000'
              width='100%'

            />

            <EditableTable
              id="managers"
              label="Managers"
              value={managers}
              columns={['Nombre', 'Apellido', 'Email', 'Teléfono']}
              onChange={(value,event) => handleTableChange(value, event)}
              editor={editorForm}
            />

            <Multivalue
              id="teams"
              label="Equipos"
              value={teams}
              onChange={setTeams}
            />

            <AWSLogoPicker
              id="logo"
              label="Logo"
              secondLabel="*"
              endpoint={signedUrl}
              value={logo}
              onChange={changeFile}
              onLogoBackgroundColorChange={setLogoBckColor}
              onBackgroundColorChange={setBckColor}
              onTextColorChange={setTextColor}

            />

          </main>
          <footer>
            <Button
              id='btnGuardar'
              value='Guardar'
              onClick={handleSubmit}
              enabled={validForm} />
          </footer>

        </Form>
      </CardContainer>
    </Page>
  );
}
