import { Route, Routes } from "react-router-dom";

import List from './list';
import View from './view';
import Edit from './edit';
import Create from './create';

import NotFound from '../error-400';
import Apply from "./apply";

export const endpoint = '/requirements';

export const labels = {
  title: 'Requerimientos',
  link: '/requirements',
  actions: {
    list: 'Listado',
    new: ' Nuevo',
    edit: 'Editar',
    apply: 'Aplicar',
  }
}

export default function IndexPage() {
  return <Routes>
      <Route exact path="/new" element={<Create endpoint={endpoint} labels={labels} />} />
      <Route exact path="/edit/:id" element={<Edit endpoint={endpoint} labels={labels} />} />
      <Route exact path="/view/:id" element={<View />} />
      <Route exact path="/applicants/:id" element={<Apply endpoint={endpoint} labels={labels} />} />
      <Route exact path="/" element={<List />} />
      <Route path="*" element={<NotFound />} />
    </Routes> 
}
