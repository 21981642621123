import { Form } from '../../../components/form';
import { labels, endpoint } from '.';
import { regex } from '@23people/moonbase-validators-v3';
import { useForm } from '../../../components/form';
import { useState, useEffect } from 'react';
import apiRequest from '../../../lib/api-request';
import Button from '../../../components/button';
import CardContainer from '../../../components/card-container';
import Page from '../../../components/Page';
import TextArea from '../../../components/text-area';
import TextField from '../../../components/text-field';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';


export const fetchData = async (url) => {

  let baseUrl = `${process.env.REACT_APP_API_URL}${url}`;

  const result = await apiRequest(baseUrl);
  if (!result.ok) {
    throw new Error('El resgistro no existe')
  }
  const json = await result.json();

  return json;
};

export default function Edit() {

  const { id } = useParams();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const handleSubmit = () => {
    actions.submitUpdate(id, { name, description }, 'name');
  }

  const validForm = () => {
    return !!name && !!description;
  }

  const [ actions ] = useForm(endpoint, validForm);

  const { data, isError, isLoading, status } = useQuery(
    [`${endpoint}/${id}`],
    () => fetchData(`${endpoint}/${id}`)
  );

  useEffect(() => {
    if (status === 'success') {

      setName(data.name);
      setDescription(data.description);
    }
  }, [data, status]);

  if (isLoading) return 'Cargando...';
  if (isError) return 'Ocurrió un error';

  return (
    <Page title={labels.title} breadcrumb={[{ label: labels.title, link: labels.link }, {label: labels.actions.edit, link: ''}]}>
      <CardContainer cssClass='__card-container __form'>
        <Form>
          <main>
            
            <TextField
              id='name'
              label='Nombre'
              placeholder='Ej: Seguro de vida'
              secondLabel="*"
              maxLength='60'
              required={true}
              requiredText="El campo es requerido"
              value={name}
              onChange={setName}
              pattern={regex.TEXT}
              validationText="Por favor ingrese un nombre válido"
            />

            <TextArea
              id='description'
              label='Descripción'
              secondLabel="*"
              maxLength='400'
              placeholder={'Ej: Seguro de vida para todos los empleados'}
              required={true}
              value={description}
              onChange={setDescription}
              validationText={'Por favor ingrese una descripción válida'}
            />

          </main>

          <footer>
            <Button
              id='btnGuardar'
              value='Guardar'
              onClick={handleSubmit}
              enabled={validForm} 
            />
          </footer>
        </Form>
      </CardContainer>
    </Page>
  )
}