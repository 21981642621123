import './flex-layout.css'


const defaultFlex = {
  flexDirection: 'row',
  gap: '1rem',
  flexWrap: 'wrap'
  
};

function FlexLayout({ cssClass = '__flex-layout', layoutType='flex', layoutOptions=defaultFlex, children }) {
  return <div className={`${cssClass}`} style={{display: layoutType, ...layoutOptions}}>
    {children}
  </div>
}

export default FlexLayout;
