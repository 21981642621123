import { Route, Routes } from "react-router-dom";

import Create from "./create";
import Edit from "./edit";
import List from './list';

import NotFound from '../error-400';

export const endpoint = '/users'; //API

export const ROLES = {
  ADMIN: 'ADMIN',
  COMMERCIAL: 'COMMERCIAL',
  RECRUITER: 'RECRUITER',
  HR: 'HR',
};

export const labels = {
  title: 'Usuarios',
  link: '/users', //link del front
  actions: {
    list: 'Listado',
    new: ' Nuevo',
    edit: 'Editar'
  }
}

export default function IndexPage() {
  return <Routes>

    <Route exact path="/new" element={<Create endpoint={endpoint} labels={labels} />} />
    <Route exact path="/edit/:id" element={<Edit endpoint={endpoint} labels={labels} />} />
    <Route exact path="/" element={<List />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
}

