import { regex } from '@23people/moonbase-validators-v3';

import Button from '../button';
import CardContainer from '../card-container';
import Page from '../Page';
import TextField from '../text-field';
import { Form, useForm } from '../form';

import { useState } from 'react';

export default function CreatePage({ endpoint, labels, nameRegex = regex.TEXT }) {

  const [name, setName] = useState('');

  const [valid, isValid] = useState(false);

  const validForm = !!name && valid;  //check if form is valid. Required + Valid Fields

  const [actions] = useForm(endpoint, validForm, labels.link);

  const handleValidation = (value) => {
    isValid(value.valid);

  };

  const handleSubmit = async () => {
    await actions.submitPost({ name }, 'name')
  }

  return (
    <Page title={labels.title} breadcrumb={[{ label: labels.title, link: labels.link }, { label: labels.actions.new, link: '' }]}>
      <CardContainer cssClass='__card-container __form'>
        <Form>

          <main>
            <TextField
              id='name'
              label='Nombre'
              secondLabel="*"
              maxLength='60'
              required={true}
              requiredText="El campo es requerido"
              value={name}
              onChange={setName}
              onValidation={handleValidation}
              pattern={nameRegex}
              validationText="Por favor ingrese un nombre válido"
            />
          </main>
          <footer>
            <Button
              id='btnGuardar'
              value='Guardar'
              onClick={handleSubmit}
              enabled={validForm} />
          </footer>

        </Form>
      </CardContainer>
    </Page>
  );
}
