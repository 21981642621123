import CardContainer from '../../components/card-container';
import { usePagination } from '../../components/pagination';
import { useSearchParams } from 'react-router-dom';
import Table from '../../components/table';
import Page from '../../components/Page';
import ObjectTable from '../../components/object-table';
import KpiCard from '../../components/kpi-card';
import { HiOutlineUsers } from 'react-icons/hi2';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import './home.css';
import apiRequest from '../../lib/api-request';

const endpoint = '/stats/requirements-total-time';
const applicantsSentToClient = '/stats/applicants';
const requirementEndpoint = '/requirements/all'

const fetchData = async (url) => {
  let baseUrl = `${process.env.REACT_APP_API_URL}${url}`;

  const result = await apiRequest(baseUrl);

  if (!result.ok) {
    throw new Error('El resgistro no existe')
  }
  const json = await result.json();

  return json;
};

export default function IndexPage() {

  const [queryParams] = useSearchParams();
  const [query] = usePagination(endpoint, queryParams);

  const { data, isError, isLoading } = useQuery(
    [`${requirementEndpoint}`],
    () => fetchData(`${requirementEndpoint}`)
  );

  const  applicantsStats = useQuery(
    [`${applicantsSentToClient}`],
    () => fetchData(`${applicantsSentToClient}`)
  );

  if (query.isLoading || isLoading || applicantsStats.isLoading) return 'Cargando...';

  if (query.error || isError || applicantsStats.isError) return 'Ocurrió un error: ' + query.error.message;

  const fields = [

    {
      header: '',
      fields: ['position', 'customer.name']
    },
    {
      header: 'Total',
      field: 'stats.totalTimeElapsed'
    },
    {
      header: 'Tiempo por Etapa',
      render: ({ stats }) => (
        <>
          <div className="__table_fields stat-line" style={{ color: 'rgb(137, 143, 153)', fontWeight: '400' }}>
            <span>{'Por Hacer:'}</span>
            <span>{stats ? stats.todo : '0'}</span>
          </div>
          <div className="__table_fields stat-line">
            <span>{'Preselección en Proceso:'}</span>
            <span>{stats ? stats.preselectionInProgress : '0'}</span>
          </div>
          <div className="__table_fields stat-line">
            <span>{'Preselección Listo:'}</span>
            <span>{stats ? stats.preselectionDone : '0'}</span>
          </div>
          <div className="__table_fields stat-line">
            <span>{'Selección Recibiendo Postulaciones:'}</span>
            <span>{stats ? stats.selectionInProgress : '0'}</span>
          </div>
          <div className="__table_fields stat-line">
            <span>{'Selección Candidato Enviado al Cliente:'}</span>
            <span>{stats ? stats.selectionSentToClient : '0'}</span>
          </div>
        </>
      )
    }

  ];

  return (

    <Page title="DashBoard" breadcrumb={['Dashboard', 'Visualización']} cssClass='__page dashboard'>
      <section>
        <KpiCard icon={<HiOutlineUsers size={24} />} link={<Link to={'/employees'}>Ver detalle</Link>} label='Trabajadores 23people' value='88' />
        <KpiCard icon={<HiOutlineUsers size={24} />} link={<Link to={'/countries'}>Ver detalle</Link>} label='Tiempo Primer Candidato' value='6 días' />
        <KpiCard icon={<HiOutlineUsers size={24} />} link={<Link to={'/customers'}>Ver detalle</Link>} label='Tasa Evaluados por Cliente' value='82.3 %' />
        <KpiCard icon={<HiOutlineUsers size={24} />} link={<Link to={'/requirements'}>Ver detalle</Link>} label='Tasa Aprobados por Cliente' value='43.5 %' />

        <KpiCard icon={<HiOutlineUsers size={24} />} label='Candidatos Enviados a Cliente' value={ applicantsStats.data.sent } />
        <KpiCard icon={<HiOutlineUsers size={24} />} label='Candidatos Aceptados por Cliente' value={ applicantsStats.data.accepted + ' %'} />
        <KpiCard icon={<HiOutlineUsers size={24} />} label='Candidatos Rechazados en CV por Cliente' value={ applicantsStats.data.rejectedInCV + ' %'} />
        <KpiCard icon={<HiOutlineUsers size={24} />} label='Candidatos Rechazados en Entrevista por Cliente' value={ applicantsStats.data.rejectedInInterview + ' %'} />
      </section>

      <section>
        <CardContainer cssClass='__card-container simple-table'>
          <ObjectTable label='Tiempo total del proceso' json={query.data} />
        </CardContainer>

        <CardContainer cssClass='__card-container'>
          <Table id="process-time" columns={fields} data={data.result || []} />
        </CardContainer>
      </section>
    </Page>
  );
}

