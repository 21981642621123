import React from 'react';
import PropTypes from 'prop-types';

import { emptyFn } from '../../lib/fn-utils';

//import { validators } from '@23people/moonbase-validators-formatters';

import TextField from '../text-field';

import './number.css';

const icon = () => {
	return (
		<svg
			style={{
				width: '1.25rem',
				height: '1.25rem',
				verticalAlign: 'middle',
				color: 'rgba(156, 163, 175, 1',
				cursor: ''
			}}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 20 20'
			fill='currentColor'
		>
			<path
				fillRule='evenodd'
				d='M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V4a2 2 0 00-2-2H6zm1 2a1 1 0 000 2h6a1 1 0 100-2H7zm6 7a1 1 0 011 1v3a1 1 0 11-2 0v-3a1 1 0 011-1zm-3 3a1 1 0 100 2h.01a1 1 0 100-2H10zm-4 1a1 1 0 011-1h.01a1 1 0 110 2H7a1 1 0 01-1-1zm1-4a1 1 0 100 2h.01a1 1 0 100-2H7zm2 1a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zm4-4a1 1 0 100 2h.01a1 1 0 100-2H13zM9 9a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zM7 8a1 1 0 000 2h.01a1 1 0 000-2H7z'
				clipRule='evenodd'
			/>
		</svg>
	);
};

const Numeric = ({
	id,
	cssClass ='__text-field __number',
	tabIndex,
	value = '',
	required = false,
	requiredText,
	label,
	secondLabel,
	message,
	maxLength,
	minLength,
	placeholder,
	onChange = emptyFn,
	theme,
	validationText,
	autocomplete ='np',
	min = Number.MIN_SAFE_INTEGER,
	max = Number.MAX_SAFE_INTEGER,
	format,
	type ='integer',
	locale = navigator.language,
	readOnly = false,
	width = '320px'
}) => {

	const thousandSeparator = Number(10000).toLocaleString(locale).charAt(2);
	const decimalSeparator = Number(1.1).toLocaleString(locale).charAt(1);

	const integerChars = new RegExp(`[0-9${thousandSeparator}-]`);
	const decimalChars = new RegExp(`[0-9${thousandSeparator}${decimalSeparator}-]`);
	const positiveIntegerChars = new RegExp(`[0-9${thousandSeparator}]`);
	const positiveDecimalChars = new RegExp(`[0-9${thousandSeparator}${decimalSeparator}]`);

	const allowedChars = () => {
		if (type === 'integer') {
			return integerChars;
		}

		if (type === 'decimal') {
			return decimalChars;
		}

		if (type === 'positive-integer') {
			return positiveIntegerChars;
		}

		if (type === 'positive-decimal') {
			return positiveDecimalChars;
		}

		
	}

	const checkDecimalSeparatorLast = text => text.slice(-1) === decimalSeparator; 

	const localeFormat = text => {

		if (text === '' || text === undefined || text === '-') { //cannot check falsy cause 0
			return text;
		}

		if (checkDecimalSeparatorLast(text)) {
			//check if decimal separator appears more than one time
			return text.split(decimalSeparator).length > 2 ? text.slice(0, -1) : text;

		}
		const formatted = text.replaceAll(thousandSeparator, '').replaceAll(decimalSeparator, '.');
		const number = Number(formatted);
		const result = new Intl.NumberFormat(locale).format(number);

		return result;

	};

	const isNumber = text => {
		
		const formatted = text.replaceAll(thousandSeparator, '').replaceAll(decimalSeparator, '.');
		const number = Number(formatted);

		return !isNaN(number);

	}
	

	const getPreIcon = () => {
		if (theme === 'inner-icon' || theme === 'prepend') {
			return icon;
		}
		return;
	};

	const getPostIcon = () => {
		if (theme === 'append') {
			return icon;
		}

		return;
	};

	const onChangeEv = textValue => {

		//convert to text value
		onChange(textValue);

	}


	return (
		<TextField
			id={id}
			cssClass={`${cssClass} ${theme}`}
			tabIndex={tabIndex}
			required={required}
			requiredText={requiredText}
			inputType='numeric'
			inputmode='decimal'
			value={value}
			label={label}
			secondLabel={secondLabel}
			message={message}
			autocomplete={autocomplete}
			maxLength={maxLength}
			minLength={minLength}
			placeholder={placeholder}
			onChange={onChangeEv}
			// allowedChars={allowedChars()}
			pre={getPreIcon()}
			post={getPostIcon()}
			validation={isNumber}
			validationText={validationText}
			min={min}
			max={max}
			format={localeFormat}
			readOnly={readOnly}
			width={width}
		/>
	);
};

// Numeric.defaultProps = {
// 	id: '',
// 	cssClass: '__text-field __number',
// 	value: '',
// 	required: false,
// 	requiredText: '',
// 	label: '',
// 	secondLabel: '',
// 	maxLength: '',
// 	minLength: '',
// 	placeholder: '',
// 	validationText: '',
// 	message: '',
// 	autocomplete: 'np',
// 	enabled: true,
// 	onChange: () => {},
// 	theme: '',
// 	type: 'integer',
// 	min: Number.MIN_SAFE_INTEGER,
// 	locale: navigator.language,
//   	readOnly: false
// };

Numeric.propTypes = {
	id: PropTypes.string.isRequired,
	cssClass: PropTypes.string,
	tabIndex: PropTypes.string,
	value: PropTypes.string,
	required: PropTypes.bool,
	requiredText: PropTypes.string,
	label: PropTypes.string,
	secondLabel: PropTypes.string,
	maxLength: PropTypes.string,
	minLength: PropTypes.string,
	min: PropTypes.number,
	max: PropTypes.number,
	placeholder: PropTypes.string,
	validationText: PropTypes.string,
	message: PropTypes.string,
	enabled: PropTypes.bool,
	onChange: PropTypes.func,
	theme: PropTypes.oneOf([ '', 'inner-icon', 'prepend', 'append' ]),
	autocomplete: PropTypes.string,
	type: PropTypes.oneOf(['integer', 'decimal', 'positive-integer', 'positive-decimal']),
	format: PropTypes.func,
	locale: PropTypes.string,
	readOnly: PropTypes.bool
};

export default Numeric;
