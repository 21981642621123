import { Link, useSearchParams } from 'react-router-dom';
import { usePagination } from '../../components/pagination';

import Page from '../../components/Page';

import './requirements.css';

import SearchList from '../../components/search-list';
import Avatar from '../../components/avatar';

const fields = [

  {
    header: 'Cliente',
    render: ({ customer }) => (

      <section className="first-column" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <Avatar id="1" src={customer.brand.logo} alt={`${customer.name}' Logo`} size="md" />
        <div>
          <p className="__table_fields">{customer.name}</p>

        </div>
      </section>
    )
  },
  {
    header: 'Posición',
    render: ({ position, requirementType, workingCondition }) => (
      <>
        <div className="__table_fields">{position}</div>
        <div className="__table_fields">{`${requirementType.name} - ${workingCondition.name}`}</div>
      </>

    )
  },
  {
    header: 'Nivel Inglés',
    field: 'englishLevel.name'
  },
  {
    header: 'Cantidad',
    field: 'applicantsNumber'
  },
  {
    header: 'Fecha Inicio',
    render: ({ startDate }) => {
      if (startDate) {
        return new Date(startDate).toLocaleDateString();
      }

    }
  },
  {
    header: 'País',
    field: 'country.name'
  }
];

const createActions = {
  render: () => <Link to="new" className="create-actions">
    Nuevo
  </Link >
};

const getFields = (actions) => {
  return [
    ...fields,
    {
      header: "",
      actions: [
        {
          render: ({ _id, position }) => (
            <section style={{ display: 'flex', gap: '10px' }}>
              <Link to={`applicants/${_id}`} className="link">
                Postular
              </Link>
              <Link to={`edit/${_id}`} className="link">
                Editar
              </Link>
              <span onClick={() => actions.handleDelete(_id, position)}>Eliminar</span>
            </section>
          ),
        }
      ]
    }
  ];
}

const endpoint = '/requirements';

export default function List() {
  const [queryParams] = useSearchParams();

  const [query,
    pagination,
    actions,
    navigate] = usePagination(endpoint, queryParams);

  if (query.isLoading) return 'Loading...';

  if (query.error) return 'An error has occurred: ' + query.error.message;

  return (

    <Page title="Requerimientos" breadcrumb={['Requerimientos', 'Listado']}>

      <SearchList
        searchText="Buscar"
        filterValue={pagination.searchFilter}
        onSearchChange={pagination.setSearchFilter}
        onSearch={actions.searchByFilter}
        fields={getFields(actions)}
        data={query.data.result}
        currentPage={pagination.page}
        pageCount={query.data.pageCount}
        hasPrevious={query.data.hasPrevious}
        hasNext={query.data.hasNext}
        onNavigateToFirstPage={navigate.toFirstPage}
        onNavigateToLastPage={navigate.toLastPage}
        onNavigateToNextPage={navigate.toNextPage}
        onNavigateToPreviousPage={navigate.toPreviousPage}
        actions={createActions}

      />

    </Page>

  );
}
