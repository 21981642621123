import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { regex } from '@23people/moonbase-validators-v3';

import Button from '../../components/button';
import CardContainer from '../../components/card-container';
import Page from '../../components/Page';
import TextField from '../../components/text-field';
import { Form, useForm } from '../../components/form';
import NewTextField from '../../components/new-text-field';
import apiRequest from '../../lib/api-request';

export const fetchData = async (url) => {

  let baseUrl = `${process.env.REACT_APP_API_URL}${url}`;

  const result = await apiRequest(baseUrl);

  if (!result.ok) {
    throw new Error('El resgistro no existe')
  }
  const json = await result.json();

  return json?.result.length > 0 ? json?.result[0] : null;
};

export default function EditPage({ endpoint, labels, nameRegex = regex.TEXT }) {

  const [boardId, setBoardId] = useState('');
  const [boardName, setBoardName] = useState('');
  const [requirementsWorkflow, setRequirementsWorkflow] = useState([]);
  const [applicantsWorkflowWorkflow, setApplicantsWorkflowWorkflow] = useState([]);

  const [valid, isValid] = useState(false);

  const validForm = !!boardId && valid;  //check if form is valid. Required + Valid Fields

  const [actions] = useForm(endpoint, validForm, labels.link);

  const { data, isError, isLoading, status } = useQuery(
    [`${endpoint}`],
    () => fetchData(`${endpoint}`)
  );

  useEffect(() => {
    if (status === 'success') {
      setBoardId(data?.boardId.toString());
      setBoardName(data?.boardName);
      setRequirementsWorkflow(data?.requirementsWorkflow?.columns);
      setApplicantsWorkflowWorkflow(data?.applicantsWorkflow?.columns);
    }
  }, [data, status]);

  if (isLoading) return 'Cargando...';

  if (isError) return 'Ocurrió un error';

  const handleValidation = (value) => {
    isValid(value.valid);

  };

  const handleSubmit = async () => {
    await actions.submitPost({ boardId }, 'boardId')
  }

  return (
    <Page title={labels.title} breadcrumb={[{ label: labels.title, link: labels.link }, { label: labels.actions.edit, link: '' }]}>
      <CardContainer cssClass='__card-container __form'>
        <Form>
          <main>
            {isError && <span style={{ color: 'red' }}>El registro no existe</span>}

            <TextField
              id='boardId'
              label='Id Tablero Kanbanize'
              secondLabel="*"
              maxLength='60'
              required={true}
              requiredText="El campo es requerido"
              value={boardId}
              onChange={setBoardId}
              onValidation={handleValidation}
              pattern={regex.INTEGER}
              enabled={true}
              validationText="Por favor ingrese un número de tablero válido"
            />

            <TextField
              id='boardName'
              label='Nombre Tablero'
              maxLength='60'
              value={boardName}
              enabled={false}
            />

          </main>
          <footer>
            <Button
              id='btnGuardar'
              value='Guardar'
              onClick={handleSubmit}
              enabled={validForm} />
          </footer>

        </Form>
      </CardContainer>
    </Page>
  );
}
