const transformDiacritic = value =>
  value
    .replace(/[aàáâãäå]/gi, 'a')
    .replace(/[eèéêë]/gi, 'e')
    .replace(/[iìíîï]/gi, 'i')
    .replace(/[oòóôõö]/gi, 'o')
    .replace(/[uùúûü]/gi, 'u')
    .replace(/[nñ]/gi, 'n');

const escapeChars = value => (value ? transformDiacritic(value).toLowerCase() : value);

const getPrevIndex = (index, array = []) => {
  return !Number.isInteger(index) || index <= 0 || index > array.length - 1 ? array.length - 1 : index - 1;
};

const getNextIndex = (index, array = []) => {
  return !Number.isInteger(index) || index < 0 || index >= array.length - 1 ? 0 : index + 1;
};

const scrollToFocusedChild = (container, index) => {
  if (!container?.children || !Number.isInteger(index) || index < 0) return;
  const parent = container;
  const child = container?.children[index];
  if (parent.scrollHeight > parent.clientHeight) {
    const scrollBottom = parent.clientHeight + parent.scrollTop;
    const elementBottom = child.offsetTop + child.offsetHeight;
    if (elementBottom > scrollBottom) {
      parent.scrollTop = elementBottom - parent.clientHeight;
    } else if (child.offsetTop < parent.scrollTop) {
      parent.scrollTop = child.offsetTop;
    }
  }
};

export { escapeChars, getPrevIndex, getNextIndex, scrollToFocusedChild };
