import { convertListToObject, editorForm } from './commons';
import { Form, useForm } from '../../components/form';
import { labels, endpoint } from '.';
import { regex } from '@23people/moonbase-validators-v3';
import { useParams } from "react-router-dom"
import { useQuery } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import apiRequest from '../../lib/api-request';
import AWSLogoPicker from '../../components/aws-logo-picker';
import Button from '../../components/button';
import CardContainer from '../../components/card-container';
import EditableTable from '../../components/editable-table';
import Multivalue from '../../components/multivalue';
import Page from '../../components/Page';
import TextArea from '../../components/text-area';
import TextField from '../../components/text-field';

export const fetchData = async (url) => {

  let baseUrl = `${process.env.REACT_APP_API_URL}${url}`;

  const result = await apiRequest(baseUrl);
  if (!result.ok) {
    throw new Error('El resgistro no existe')
  }
  const json = await result.json();

  return json;
};

export default function Edit() {

  const [about, setAbout] = useState('');
  const [bckColor, setBckColor] = useState('white');
  const [etag, setEtag] = useState();
  const [file, setFile] = useState();
  const [logo, setLogo] = useState('/img/logo-ph-400.png');
  const [logoBckColor, setLogoBckColor] = useState('white');
  const [managers, setManagers] = useState([]);
  const [name, setName] = useState();
  const [teams, setTeams] = useState([]);
  const [textColor, setTextColor] = useState('red');
  const [valid, isValid] = useState(false);
  const { id } = useParams();

  const signedUrl = `${process.env.REACT_APP_API_URL}/files/signing`;

  const validForm = !!name && valid;  //check if form is valid. Required + Valid Fields

  const [actions] = useForm(endpoint, validForm);

  const { data, isError, isLoading, status } = useQuery(
    [`${endpoint}/${id}`],
    () => fetchData(`${endpoint}/${id}`)
  );


  useEffect(() => {
    if (status === 'success') {
      setName(data.name);
      setAbout(data.about);
      setTeams(data.teams);
      setBckColor(data.brand.backgroundColor);
      setLogoBckColor(data.brand.logoBackgroundColor);
      setTextColor(data.brand.textColor);
      setLogo(data.brand.logo);
      setManagers(data.managers)
    }
  }, [data, status]);


  if (isLoading) return 'Cargando...';

  if (isError) return 'Ocurrió un error';

  const handleValidation = (value) => {
    isValid(value.valid);
    console.log(value);
  };

  const changeFile = ({ filename, etag }) => {
    setFile(filename);
    setEtag(etag);

    const logoUrl = `${process.env.REACT_APP_API_URL}/files/show/${filename}`;  
    setLogo(logoUrl);

  }

  const handleSubmit = async () => {
    await actions.submitUpdate(id, 
      { name, 
        about, 
        teams, 
        brand: {
          logo,
          textColor,
          logoBackgroundColor: logoBckColor,
          backgroundColor: bckColor,
      },
      managers
    }, 'name')
  }


  const handleTableChange = (newValue, event) => {
  
    if(event === 'add'){
      const obj = convertListToObject(newValue);
      setManagers([...managers, obj]);
    }else if(event ==='remove'){
      const filterManagers = managers.filter(manager => manager.email !== newValue.email);
      setManagers(filterManagers)
    }
  }


  return (
    <Page title={labels.title} breadcrumb={[{ label: labels.title, link: labels.link }, { label: labels.actions.edit, link: '' }]}>
      <CardContainer cssClass='__card-container __form'>
        <Form>
          <main>
            {isError && <span style={{ color: 'red' }}>El registro no existe</span>}

            <TextField
              id='name'
              label='Nombre'
              secondLabel="*"
              maxLength='60'
              required={true}
              requiredText="El campo es requerido"
              value={name}
              onChange={setName}
              onValidation={handleValidation}
              pattern={regex.TEXT}
              enabled={true}
              validationText="Por favor ingrese un nombre válido"
            />

            <TextArea
              id='about'
              label='Acerca De'
              value={about}
              onChange={setAbout}
              maxLength='1000'
              width='100%'
              onValidation={handleValidation}
            />

            <EditableTable
              id="managers"
              label="Managers"
              value={managers}
              columns={['Nombre', 'Apellido', 'Email', 'Teléfono']}
              onChange={(value, e) => handleTableChange(value, e)}
              editor={editorForm}
              onValidation={handleValidation}
            />

            <Multivalue
              id="teams"
              label="Equipos"
              value={teams}
              onChange={setTeams}
              onValidation={handleValidation}
            />

            <AWSLogoPicker
              id="logo"
              label="Logo"
              secondLabel="*"
              endpoint={signedUrl}
              value={logo}
              onChange={changeFile}
              onLogoBackgroundColorChange={setLogoBckColor}
              bgColor={bckColor}
              logoBgColor={logoBckColor}
              txtColor={textColor}
              onBackgroundColorChange={setBckColor}
              onTextColorChange={setTextColor}
              onValidation={handleValidation}
            />

          </main>
          <footer>
            <Button
              id='btnGuardar'
              value='Guardar'
              onClick={handleSubmit}
              enabled={validForm} />
          </footer>

        </Form>
      </CardContainer>
    </Page>
  );
}
