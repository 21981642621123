import { Route, Routes } from "react-router-dom";

import CreatePage from "../../../components/pages/CreatePage";
import EditPage from "../../../components/pages/EditPage";
import ListPage from "../../../components/pages/ListPage";

import NotFound from '../../error-400';

export const endpoint = '/english-levels';

export const labels = {
  title: 'Nivel de Inglés',
  link: '/english-levels',
  actions: {
    list: 'Listado',
    new: ' Nuevo',
    edit: 'Editar'
  }
}

export default function IndexPage() {
  return <Routes>
    <Route exact path="/new" element={<CreatePage endpoint={endpoint} labels={labels} />} />
    <Route exact path="/edit/:id" element={<EditPage endpoint={endpoint} labels={labels} />} />
    <Route exact path="/" element={<ListPage endpoint={endpoint} labels={labels} />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
}

