import { Link, useSearchParams } from 'react-router-dom';

import { usePagination } from '../../../components/pagination';
import Page from '../../../components/Page';
import SearchList from '../../../components/search-list';

import { labels, endpoint } from '.';

const createActions = {
  render: () => <Link to="new" className="create-actions">
    Nuevo
  </Link >
};

export default function List() {

  const [queryParams] = useSearchParams();

  const [query,
    pagination,
    actions,
    navigate] = usePagination(endpoint, queryParams);

  if (query.isLoading) return 'Cargando...';

  if (query.error) return 'Ocurrió un error: ' + query.error.message;

  const fields = [
    {
      header: 'Nombre',
      field: 'name'
    },

    {
      header: 'Categoría',
      field: 'category'
    },

    {
      header: 'Relacionado',
      render: ({ relatedTo }) => (
        <section className='managers-list'>
          <ul>
            {
              relatedTo.map((el, idx) =>
                <li key={`${el}-${idx}`}>{el} </li>
              )
            }
          </ul>

        </section>
      )

    },

    {
      header: 'Dependiente',
      render: ({ dependsOn }) => (
        <section className='managers-list'>
          <ul>
            {
              dependsOn.map((el, idx) =>
                <li key={`${el}-${idx}`}>{el} </li>
              )
            }
          </ul>

        </section>
      )
    },

    {
      header: "",
      actions: [
        {
          render: ({ _id, name }) => (
            <section style={{ display: 'flex', gap: '10px' }}>
              <Link to={`edit/${_id}`} className="link">
                Editar
              </Link>
              <span onClick={() => actions.handleDelete(_id, name)}>Eliminar</span>
            </section>
          ),
        }
      ]
    }
  ];

  return (

    <Page title={labels.title} breadcrumb={[{ label: labels.title, link: labels.link }, { label: labels.actions.list, link: '' }]}>
      
      <SearchList
        searchText="Buscar"
        filterValue={pagination.searchFilter}
        onSearchChange={pagination.setSearchFilter}
        onSearch={actions.searchByFilter}
        fields={fields}
        data={query.data.result}
        currentPage={pagination.page}
        pageCount={query.data.pageCount}
        hasPrevious={query.data.hasPrevious}
        hasNext={query.data.hasNext}
        onNavigateToFirstPage={navigate.toFirstPage}
        onNavigateToLastPage={navigate.toLastPage}
        onNavigateToNextPage={navigate.toNextPage}
        onNavigateToPreviousPage={navigate.toPreviousPage}
        actions={ createActions }
      
      />
      
    </Page>

   
  );
}
