import './editable-table.css';
import { BsPlusLg } from 'react-icons/bs';
import { FaTrashAlt } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import Button from '../button';
import TextField from '../text-field';

export default function EditableTable({
  id,
  label,
  value = [],
  cssClass = '__editable-table',
  columns = [],
  onChange = () => { },
  onValidation = () => { },
  editor = [],
}) {
  const [inputValues, setInputValues] = useState([]);
  const [list, setList] = useState(value);
  const [showEditor, setShowEditor] = useState(false);

  useEffect(() => {
    setList(value);
  }, [value]);

  const add = () => {
    const newValues = [...inputValues];

    if(newValues.length === 0 || newValues.some(el => el === '')) {
      return;
    }

    setInputValues(Array(editor.length).fill(''));
    onChange(newValues, 'add');
    onValidation({ field: id, valid: true })
  };


  const handleInputChange = (index, newValue) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = newValue;
    setInputValues(newInputValues);
  }

  const remove = selected => {
    onChange(selected, 'remove');
    onValidation({ field: id, valid: true })
  };

  const toggleFirstRow = () => {
    setShowEditor(!showEditor);
  };


  return (
    <section className={cssClass}>
      <table id={id} className='divide-y divide-gray-200' width='448px'>
        <thead>
          <tr>
            <th width='288px'>{label}</th>
            <th></th>
            <th></th>
            <th width='32px'>
              <BsPlusLg size={16} onClick={toggleFirstRow} />
            </th>
          </tr>
          <tr>
            {columns.map(column => (
              <th key={column}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody className='divide-y divide-gray-200'>
          {showEditor && (
            <tr>
              <td colSpan={editor.length + 2}>
                <section className='__editor-container'>
                  {editor &&
                    editor.map((el, index) => {
                      return <TextField id={el.label} onChange={(newValue) => handleInputChange(index, newValue)} value={inputValues[index]} placeholder={el.label} key={index} width={'200px'} addCss='__editor-text-box' />;
                    })}
                  <Button
                    id='btnAdd'
                    cssClass='__button __editor-add-button'
                    value='Agregar'
                    onClick={add}
                  />
                </section>

              </td>

              <td className='first-row'>

              </td>
            </tr>
          )}

          {list.map(element => (
            <tr key={element['name'] + ' ' + element['lastName']}>
              <td>{element['name'] + ' ' + element['lastName']}</td>
              <td>
                <Button
                  id='btnAdd'
                  cssClass='__button __remove-button'
                  value={<FaTrashAlt size={14} />}
                  onClick={() => remove(element)}
                />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot />
      </table>
    </section>
  );
}
