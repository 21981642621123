import Page from "../../components/Page"
import { PdfViewer } from "../../components/pdf-viewer/pdf-viewer";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import apiRequest from "../../lib/api-request";


export default function CV(){

  const [file, setFile] = useState('');
  const { fileName } = useParams();

  const { isError, isLoading } = useQuery(['cvShow'], async () => {

    const url = `${process.env.REACT_APP_API_URL}/cv/show/${fileName}`;
    const headers = { 'Accept': 'application/pdf' }
    const response = await apiRequest(url,headers)

    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    const blobObject = await response.blob();
    const pdfUrl = URL.createObjectURL(blobObject);
    
    if(pdfUrl){
      setFile(pdfUrl);
    }

    return pdfUrl;
  })

  if(isLoading) return 'Loading...'
  if(isError) return 'Error'


  return(
    <Page title="Curriculum Vitae">
      <PdfViewer pdfUrl={file}/>
    </Page>
  )
}