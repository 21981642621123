import React from 'react'
import PropTypes from 'prop-types'
import './button.css'

const Button = ({
  id,
  cssClass = '__button',
  autoFocus = false,
  value,
  enabled=true,
  form,
  onClick,
  type='button',
  tabIndex=0,
  size='md'
}) => {
  return (
    <button
      id={id}
      name={id}
      className={`${cssClass} __button__${size}`}
      disabled={!enabled}
      onClick={onClick}
      type={type}
      tabIndex={tabIndex}
      autoFocus={autoFocus}
      form={form}
    >
      {value}
     
    </button>
  )
}

Button.propTypes = {
  autoFocus: PropTypes.bool,
  cssClass: PropTypes.string,
  value: PropTypes.node,
  enabled: PropTypes.bool,
  form: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string.isRequired,
  tabIndex: PropTypes.number,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl'])
}

export default Button
