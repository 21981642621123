import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { regex } from '@23people/moonbase-validators-v3'; 

import { emptyFn, isFunction } from '../../lib/fn-utils';

import './text-area.css';


const validationFn = (value, pattern) => pattern ? pattern.test(value) : true;

const TextArea = ({
	id,
	cssClass = '__text-area',
	addCss = '',
	inputType = 'text',
	value,
	required=false,
	requiredText,
	label,
	secondLabel,
	pre,
	post,
	message,
	autocomplete='off',
	inputmode,
	maxLength,
	minLength,
	min,
	max,
	placeholder,
	validationText,
	enabled = true,
	pattern=regex.TEXT,
	step,
	tabIndex,
	list,
	validation = validationFn,
	allowedChars=regex.TEXT,
	onChange=emptyFn,
	onCopy,
	onEnter=emptyFn,
	onValidation = validationFn ,
	format,
	readOnly=false,
	width = '320px',
	rows = 3,
	cols=32

}) => {


	const [text, setText] = useState(''); //do not remove ''
	const [valid, setValid] = useState(true);
	const [showRequired, setShowRequired] = useState(false);

	// TODO: this is weird but i can't find another way to update text based on prop
	useEffect(() => {
		setText(value);

	}, [value]);

	const inputCss = () => {
		if (!pre && !post) {
			return 'unique';
		}

		if (pre && !post) {
			return 'second';
		}

		if (!pre && post) {
			return 'first';
		}

		return 'middle';
	};

	const onLostFocus = () => {
		//setValid(validation(text));
		setShowRequired(true);
	}

	const onFocus = (event) => {
		event.target.select();
		setShowRequired(false);
	}


	const onChangeEv = newValue => {
		
		const formatted = isFunction(format) ? format(newValue) : newValue;
		const isValid = validation(formatted, pattern);

		if (inputType === 'numeric' && !isValid) {
			return;
		}
		
		setValid(isValid);
		setText(formatted);
		onChange(formatted);
		onValidation({ field: id, valid: isValid, requiredOk: required && formatted ? true : false });

	};

	const onKeyPressEv = e => {
		
		if (e.key === 'Enter') {
			onEnter(text);
			return;
		}

		// Delete key is not captured on keypress
		if (allowedChars && !allowedChars.test(e.key)) {
			e.preventDefault();
		}

		return 1;

	}

	const onPaste = e => {
		const pasted = e.clipboardData.getData('text');

		if (allowedChars && !allowedChars.test(pasted)) {
			e.preventDefault();
		}
	
	}

	return (
		<section style={{ width }} className={`${cssClass} ${!valid ? 'invalid' : ''} ${showRequired && required && !text ? 'required' : ''}  ${addCss}`} >
			<label htmlFor={id}>
				<span>{label}</span>
				<span>{secondLabel}</span>
			</label>

			<div style={{ width }}>
				{pre ? <span className='pre-section'>{isFunction(pre) ? pre() : pre}</span> : null}
				<textarea
					id={id}
					name={id}
					className={inputCss()}
					type={inputType === 'numeric' ? 'text' : inputType}
					value={text}
					required={required}
					onKeyDown={onKeyPressEv}
					onChange={e => onChangeEv(e.target.value)}
					onFocus={onFocus}
					onBlur={onLostFocus}
					onPaste={onPaste}
					onCopy={onCopy}
					placeholder={placeholder}
					autoComplete={autocomplete}
					inputMode={inputmode}
					minLength={minLength}
					maxLength={maxLength}
					min={min}
					max={max}
					disabled={!enabled}
					pattern={pattern}
					step={step}
					tabIndex={tabIndex}
					list={list}
					readOnly={readOnly}
					cols={cols}
					rows={rows}
				/>
				{post ? (
					<span className='post-section'>{isFunction(post) ? post() : post}</span>
				) : null}
			</div>

			<span className='message'>{message}</span>

			{!valid ? <span className='validationMessage'>{validationText}</span> : null}

			{showRequired && required && !text ? <span className='requiredMessage'>{requiredText}</span> : null}

		</section>
	);
};


TextArea.propTypes = {
	id: PropTypes.string.isRequired,
	cssClass: PropTypes.string,
	tabIndex: PropTypes.string,
	inputType: PropTypes.string,
	value: PropTypes.string,
	required: PropTypes.bool,
	label: PropTypes.string,
	secondLabel: PropTypes.string,
	maxLength: PropTypes.string,
	minLength: PropTypes.string,
	placeholder: PropTypes.string,
	validationText: PropTypes.string,
	min: PropTypes.number,
	max: PropTypes.number,
	step: PropTypes.string,
	message: PropTypes.string,
	autocomplete: PropTypes.string,
	inputmode: PropTypes.string,
	hasError: PropTypes.string,
	enabled: PropTypes.bool,
	pattern: PropTypes.instanceOf(RegExp),
	list: PropTypes.string,
	readOnly: PropTypes.bool,
	width: PropTypes.string,
	pre: PropTypes.oneOfType([ PropTypes.string, PropTypes.func ]),
	post: PropTypes.oneOfType([ PropTypes.string, PropTypes.func ]),
	validation: PropTypes.func,
	allowedChars: PropTypes.instanceOf(RegExp),
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onCopy: PropTypes.func,
	format: PropTypes.func,
	onEnter: PropTypes.func,
	onValidation: PropTypes.func
};

export default TextArea;
