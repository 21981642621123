import { useEffect, useState } from 'react';

import apiRequest from '../../lib/api-request';
import LogoPicker from '../logo-picker';
import './aws-logo-picker.css';

const emptyFn = () => { };

const extract = (tag, xml) => {
  return xml.split(`<${tag}>`)[1].split(`</${tag}>`)[0];
};

const AWSLogoPicker = ({
  id,
  label,
  value,
  eTag,
  secondLabel,
  src,
  bgColor,
  logoBgColor,
  txtColor,
  endpoint,
  onChange = emptyFn,
  onLogoBackgroundColorChange = emptyFn,
  onSrcChange = emptyFn,
  onBackgroundColorChange = emptyFn,
  onTextColorChange = emptyFn,
  onValidation = emptyFn
}) => {
  const [filename, setFilename] = useState('');
  const [etag, setEtag] = useState('');

  useEffect(() => {
    setFilename(value);
  }, [value]);

  useEffect(() => {
    setEtag(eTag);
  }, [eTag]);

  const uploadFile = async ({ url, fields }, file) => {
    const formdata = new FormData();
    formdata.append('Key', fields.Key);
    formdata.append('bucket', fields.bucket);
    formdata.append('X-Amz-Algorithm', fields['X-Amz-Algorithm']);
    formdata.append('X-Amz-Credential', fields['X-Amz-Credential']);
    formdata.append('X-Amz-Date', fields['X-Amz-Date']);
    formdata.append('Policy', fields.Policy);
    formdata.append('X-Amz-Signature', fields['X-Amz-Signature']);
    formdata.append('Content-Type', fields['Content-Type']);
    formdata.append('success_action_status', fields['success_action_status']);

    formdata.append('file', file);

    try {

      const response = await apiRequest(url, {}, 'POST', formdata, false);

      const result = await response.text();
      const key = extract('Key', result);
      const tag = extract('ETag', result);

      setFilename(key);
      setEtag(tag);

      onChange({ filename: key, etag: tag });
    } catch (error) {
      console.error(error);
    }

  };

  const handleChange = async (url, file) => {
    const json = {
      fileName: file.name,
    };

    const headers = {
      'Content-Type': 'application/json',
    }

    const response = await apiRequest(endpoint, headers, 'POST', json);

    if (!response.ok) {
      console.error('Error');

      return;
    }

    const data = await response.json();

    uploadFile(data, file);
    onSrcChange(url);
  };

  const changeLogoBackgroundColor = value => {
    onLogoBackgroundColorChange(value);
    const isValid = value !== logoBgColor;
    onValidation({ field: id, valid: isValid })
  };

  const changeBackgroundColor = value => {
    onBackgroundColorChange(value);
    const isValid = value !== bgColor;
    onValidation({ field: id, valid: isValid })
  };

  const changeTextColor = value => {
    onTextColorChange(value);
    const isValid = value !== txtColor;
    onValidation({ field: id, valid: isValid })
  };

  return (
    <LogoPicker
      id={id}
      label={label}
      secondLabel={secondLabel}
      backgroundColor={bgColor}
      logoBackgroundColor={logoBgColor}
      textColor={txtColor}
      src={value}
      onChange={handleChange}
      onLogoBackgroundColorChange={changeLogoBackgroundColor}
      onTextColorChange={changeTextColor}
      onBackgroundColorChange={changeBackgroundColor}
      onValidation={onValidation}
    />
  );
};

export default AWSLogoPicker;
