import apiRequest from "../lib/api-request";

export const fetchAll = async (endpoint) => {

  let url = `${endpoint}/all`;

  return fetchData(url);

}

export const fetchData = async url => {
  let baseUrl = `${process.env.REACT_APP_API_URL}${url}`;

  const response = await apiRequest(baseUrl);

  if (!response.ok) {
    throw new Error('El resgistro no existe');
  }
  const json = await response.json();
  return json;

};

/**
 * Backend API urls
 */
export const api = {
  applications: '/application',
  benefits: '/benefits',
  benefitsType: '/benefits-type',
  countries: '/countries',
  currencies: '/currencies',
  customers: '/customers',
  cvfilter: '/cv-filter',
  cvshow: '/cv',
  employees: '/employees',
  englishLevels: '/english-levels',
  exEmployees: '/ex-employees',
  platforms: '/applicant-origin',
  positionLevels: '/position-levels',
  positions: '/positions', 
  requirements: '/requirements',
  requirementTypes: '/requirement-types',
  settings: '/settings',
  skills: '/skills',
  users: '/users',
  workingConditions: '/working-conditions',
}

