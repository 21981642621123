import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import './form.css'
import apiRequest from '../../lib/api-request';

export function Form({ cssClass = '', children }) {

  return <section className={`__form ${cssClass}`}>{children}</section>
}

export function useForm(endpoint, validationFn, destUrl) {

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const submitPost = async (json, field) => {

    if (!validationFn) {
      return;
    }

    const url = `${process.env.REACT_APP_API_URL}${endpoint}`;

    const headers = {
      'Content-Type': 'application/json',
    }

    const response = await apiRequest(url, headers, 'POST', json);

    if (response.ok) {
      queryClient.invalidateQueries(endpoint);
      toast.success(`${json[field]} creado exitosamente.`);
      navigate(destUrl || endpoint);
    } else {
      toast.error(`Error en la creación de ${json[field]}`);
    }

  }

  const submitUpdate = async (id, json, field) => {

    if (!validationFn) {
      return;
    }

    const url = `${process.env.REACT_APP_API_URL}${endpoint}/${id}`;
    
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${window.localStorage.getItem('jwt')}`
    }

    const response = await apiRequest(url, headers, 'PUT', json);

    if (response.ok) {
      queryClient.invalidateQueries(endpoint);
      queryClient.invalidateQueries(`${endpoint}/${id}`);

      toast.success(`${json[field]} actualizado exitosamente.`);
      navigate(destUrl || endpoint);
    } else {
      toast.error(`Error en la creación de ${json[field]}`);
    }

  }

  const actions = {
    submitPost,
    submitUpdate
  }

  return [actions]
  
}
