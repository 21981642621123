
import { useEffect, useState } from "react";
import { useQuery } from '@tanstack/react-query';

import SelectBox from "../select-box";

import { emptyFn } from '../../lib/fn-utils';

import { fetchAll } from '../../api';

const SelectRest = ({ id,
  endpoint = '',
  cssClass = '__select-box',
  value = '',
  valueField = '',
  labelField = '',
  required = false,
  requiredText = 'El campo es obligatorio',
  label = '',
  secondLabel = '',
  message = '',
  placeholder = 'Seleccione una opción',
  noOptionsText = 'No hay opciones',
  noResultsText = 'No hay resultados',
  disabled = false,
  maxLength = 256,
  disabledSearch = false,
  autoFocus = false,
  onChange = emptyFn,
  onFocus = emptyFn,
  onLostFocus = emptyFn,
  tabIndex = null,
  width = '100%'
}) => {

  const [options, setOptions] = useState([]);

  const { data, isLoading, isError, status } = useQuery([endpoint], () => fetchAll(endpoint));

  useEffect(() => {
    if (status === 'success') {
      setOptions(data.result);
    }
  }, [data, status]);

  if (isLoading) return 'Cargando...';

  if (isError) return 'Ocurrió un error';

  return <SelectBox
    id={id}
    cssClass={cssClass}
    label={label}
    required={required}
    requiredText={requiredText}
    secondLabel={secondLabel}
    message={message}
    noOptionsText={noOptionsText}
    noResultsText={noResultsText}
    disabled={disabled}
    maxLength={maxLength}
    disabledSearch={disabledSearch}
    autoFocus={autoFocus}
    placeholder={placeholder}
    options={options}
    value={value}
    onChange={onChange}
    onFocus={onFocus}
    onLostFocus={onLostFocus}
    valueField={valueField}
    labelField={labelField}
    tabIndex={tabIndex}
    width={width}
  />
}

export default SelectRest;