import { Form } from '../../../components/form';
import { labels, endpoint } from '.';
import { regex } from '@23people/moonbase-validators-v3';
import { useState } from 'react';
import Button from '../../../components/button';
import CardContainer from '../../../components/card-container';
import Page from '../../../components/Page';
import TextArea from '../../../components/text-area';
import TextField from '../../../components/text-field';
import { useForm } from '../../../components/form';


export default function Create() {

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const handleSubmit = async () => {
    await actions.submitPost({ name, description }, 'name');
  }

  const validForm = () => {
    return !!name && !!description;
  }

  const [ actions ] = useForm(endpoint, validForm);

  return (
    <Page title={labels.title} breadcrumb={[{ label: labels.title, link: labels.link }, {label: labels.actions.new, link: ''}]}>
      <CardContainer cssClass='__card-container __form'>
        <Form>
          <main>
            
            <TextField
              id='name'
              label='Nombre'
              placeholder='Ej: Seguro'
              secondLabel="*"
              maxLength='60'
              required={true}
              requiredText="El campo es requerido"
              value={name}
              onChange={setName}
              pattern={regex.TEXT}
              validationText="Por favor ingrese un nombre válido"
            />

            <TextArea
              id='description'
              label='Descripción'
              secondLabel="*"
              maxLength='400'
              placeholder={'Ej: tipo de beneficio que incluye seguro de vida, seguro de salud, etc.'}
              required={true}
              value={description}
              onChange={setDescription}
              validationText={'Por favor ingrese una descripción válida'}
            />

          </main>

          <footer>
            <Button
              id='btnGuardar'
              value='Guardar'
              onClick={handleSubmit}
              enabled={validForm} 
            />
          </footer>
        </Form>
      </CardContainer>
    </Page>
  )
}