import './error.css';

export default function Login() {
  return (
    <div className='main-error'>

      <img src="/img/404-a.svg" alt="not found" />

    </div>
  );
}