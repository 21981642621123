import { RxDashboard, RxLayers, RxCodesandboxLogo, RxChevronRight } from 'react-icons/rx'
import { IoPeopleOutline } from 'react-icons/io5'
import { BsGripVertical } from 'react-icons/bs'
import { Link, NavLink } from 'react-router-dom';
import './menu.css';

import useAuth from '../../hooks/useAuth';
import Avatar from '../avatar';
import { ROLES } from '../../pages/users';

const Separator = () => <hr />;

export default function Menu() {

  const { auth: { user } = {} } = useAuth(); // destructuring user from nested object

  return (
    <aside className="__sidebar" id="navigation">
      <section className="top-section">
        <header>
          <img src="/img/logo-luna.svg" height="64px" alt="logo" />
          <span>{process.env.REACT_APP_ENVIRONMENT}</span>
        </header>
        <nav>
          <ul>
            <Separator />
            <li>
              <NavLink to="/">
                <RxDashboard size={22} title="Inicio" />
                <span>Inicio</span>
              </NavLink>
            </li>
            {user?.role === ROLES.ADMIN || user?.role === ROLES.COMMERCIAL ? <li>
              <NavLink to="/requirements">
                <RxLayers size={22} title="Requerimientos" />
                <span>Requerimientos</span>
              </NavLink>
            </li> : ''}
            <li>
              <NavLink to="/customers">
                <RxCodesandboxLogo size={22} title="Clientes" />
                <span>Clientes</span>
              </NavLink>
            </li>
            {user?.role === ROLES.ADMIN || user?.role === ROLES.RECRUITER ? <li>
              <Link to="/applications">
                <IoPeopleOutline size={22} title="Postulantes" />
                <span>Postulantes</span>
              </Link>
            </li> : ''}
            {user?.role === ROLES.ADMIN || user?.role === ROLES.RECRUITER ? <li>
              <Link to="/referrals">
                <IoPeopleOutline size={22} title="Referidos" />
                <span>Referidos</span>
              </Link>
            </li> : ''}

            {user?.role === ROLES.ADMIN ? <>
              <Separator />
              <li>
                <input id="maintainers" type="checkbox" />
                <label htmlFor="maintainers">
                  {" "}
                  Mantenedores <RxChevronRight />
                </label>
                <ul className="submenu">
                  <li>
                    <NavLink to="/benefits">
                      <BsGripVertical />
                      <span>Beneficios</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/benefits-type">
                      <BsGripVertical />
                      <span>Tipo Beneficio</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/countries">
                      <BsGripVertical />
                      <span>Países</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/english-levels">
                      <BsGripVertical />
                      <span>Nivel de Inglés</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/position-levels">
                      <BsGripVertical />
                      <span>Experiencia</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/requirement-types">
                      <BsGripVertical />
                      <span>Tipo Requerimiento</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/skills">
                      <BsGripVertical />
                      <span>Habilidades</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/working-conditions">
                      <BsGripVertical />
                      <span>Condiciones Laborales</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/currencies">
                      <BsGripVertical />
                      <span>Monedas</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/platforms">
                      <BsGripVertical />
                      <span>Plataformas</span>
                    </NavLink>
                  </li>
                </ul>
              </li></> : ''}

            <Separator />
            <li>
              <input id="people" type="checkbox" />
              <label htmlFor="people">
                {" "}
                Personas <RxChevronRight />
              </label>
              <ul className="submenu">
                <li>
                  <NavLink to="/employees">
                    <BsGripVertical />
                    <span>Trabajadores</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/ex-employees">
                    <BsGripVertical />
                    <span>Ex Trabajadores</span>
                  </NavLink>
                </li>

              </ul>
            </li>

            {user?.role === ROLES.ADMIN ?
              <>
                <Separator />
                <li>
                  <input id="configuration" type="checkbox" />
                  <label htmlFor="configuration">
                    {" "}
                    Administración <RxChevronRight />
                  </label>
                  <ul className="submenu">
                    <li>
                      <NavLink to="/users">
                        <BsGripVertical />
                        <span>Usuarios</span>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/settings">
                        <BsGripVertical />
                        <span>Configuración</span>
                      </NavLink>
                    </li>

                  </ul>
                </li>
              </> : ''}

            <Separator />

            <li className='profile'>

              <Avatar id="profile" src={user?.profilePicture} alt={'Perfil'} size='lg' />
              <span> {`${user?.name} ${user?.lastName}`}</span>

            </li>
          </ul>
        </nav>
      </section>

      <footer>
      </footer>
    </aside>
  );
};
