import { regex } from '@23people/moonbase-validators-v3';

import Button from '../../components/button';
import CardContainer from '../../components/card-container';
import Page from '../../components/Page';
import TextField from '../../components/text-field';
import { Form, useForm } from '../../components/form';

import Select from '../../components/select';

import { useState } from 'react';

const roleList = [

  { label: 'Administrador', value: 'ADMIN' },
  { label: 'Comercial', value: 'COMMERCIAL' },
  { label: 'Reclutador', value: 'RECRUITER' },
  { label: 'Entrevistador', value: 'INTERVIEWER' }

]

export default function Create({ endpoint, labels }) {

  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState({});

  const [valid, isValid] = useState(false);

  const validForm = !!name && valid;  //check if form is valid. Required + Valid Fields

  const [actions] = useForm(endpoint, validForm);

  const handleValidation = (value) => {
    isValid(value.valid);

  };

  const handleSubmit = async () => {
    await actions.submitPost({ email, name, lastName, role: role.value }, 'email')
  }

  return (
    <Page title={labels.title} breadcrumb={[{ label: labels.title, link: labels.link }, { label: labels.actions.new, link: '' }]}>
      <CardContainer cssClass='__card-container __form'>
        <Form>

          <main>
            <TextField
              id='email'
              label='Email'
              secondLabel="*"
              maxLength='60'
              required={true}
              requiredText="El campo es requerido"
              value={email}
              onChange={setEmail}
              onValidation={handleValidation}
              pattern={regex.EMAIL}
              validationText="Por favor ingrese un email válido"
            />
            <TextField
              id='name'
              label='Nombre'
              secondLabel="*"
              maxLength='60'
              required={true}
              requiredText="El campo es requerido"
              value={name}
              onChange={setName}
              onValidation={handleValidation}
              pattern={regex.NAME}
              validationText="Por favor ingrese un nombre válido"
            />
            <TextField
              id='lastName'
              label='Apellido'
              secondLabel="*"
              maxLength='60'
              required={true}
              requiredText="El campo es requerido"
              value={lastName}
              onChange={setLastName}
              onValidation={handleValidation}
              pattern={regex.NAME}
              validationText="Por favor ingrese un apellido válido"
            />

            <Select
              id="role"
              label="Rol"
              required={true}
              requiredText="El campo es requerido"
              value={role}
              onChange={setRole}
              options={roleList}

            />
          </main>
          <footer>
            <Button
              id='btnGuardar'
              value='Guardar'
              onClick={handleSubmit}
              enabled={validForm} />
          </footer>

        </Form>
      </CardContainer>
    </Page>
  );
}
