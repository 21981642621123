
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom"
import { useQuery } from '@tanstack/react-query';
import Page from '../../components/Page';
import { useNavigate } from 'react-router-dom';
import apiRequest from '../../lib/api-request';
import ReferralsForm from './form';


const headers = {'Content-Type': 'application/json'};

export const fetchData = async (url) => {

  let baseUrl = `${process.env.REACT_APP_API_URL}${url}`;

  const result = await apiRequest(baseUrl);

  if (!result.ok) {
    throw new Error('El resgistro no existe')
  }
  const json = await result.json();

  return json;
};

export default function Edit({ endpoint, labels }) {

  const { id } = useParams();
  const [ values, setValues ] = useState({});
  const [valuesError, setValuesError] = useState({});

  const [isValid, setIsValid] = useState(false);
  const [referrerId, setReferrerId] = useState('');
  const [fileURL, setFileURL] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { data, isError, isLoading, status } = useQuery(
    [`${endpoint}/${id}`],
    () => fetchData(`${endpoint}/${id}`)
  );

  useEffect(() => {
    if (status === 'success') {
      setReferrerId(data.referrer._id);
      setFileURL(`${process.env.REACT_APP_API_URL}/cv/show/${data.cv.name}`);
      setValues({
        referrerName: data.referrer.name,
        referrerEmail: data.referrer.email,
        referrerPhone: data.referrer.phone,
        referredName: data.name,
        referredEmail: data.email,
        referredPhone: data.phone,
        referredPosition: data.positionToApplicate,
        referredLevel: data.experienceLevel,
        referredLinkedin: data.linkedIn,
        referredCvId: data.cv._id,
      });
      setValuesError({
        referrerName: true,
        referrerEmail: true,
        referrerPhone: true,
        referredName: true,
        referredEmail: true,
        referredPhone: true,
        referredPosition: true,
        referredLevel: true,
        referredLinkedin: true,
        referredCvId: true,
      });
    }
  }, [data, status]);

  if (isLoading) return 'Cargando...';
  if (isError) return 'Ocurrió un error';

  const handleSubmit = async () => {

    if(!isValid) {
      toast.error('Por favor, revise los campos');
      return;
    }


    try {
      if(!referrerId) {
        toast.error('Ha ocurrido un error al ingresar el Referido.');
      }

      const referrerURL = `${process.env.REACT_APP_API_URL}/referrers/${referrerId}`;
      const referrerBody = {
        name: values.referrerName,
        email: values.referrerEmail,
        phone: values.referrerPhone
      };

      await apiRequest(referrerURL, headers, 'PUT', referrerBody);

      const url = `${process.env.REACT_APP_API_URL}/referrals/${id}`;
      const body = {
        referrer: referrerId,
        name: values.referredName,
        email: values.referredEmail,
        phone: values.referredPhone,
        positionToApplicate: values.referredPosition,
        experienceLevel: values.referredLevel,
        linkedIn: values.referredLinkedin,
        cv: values.referredCvId,
      };

      const response = await apiRequest(url, headers, 'PUT', body);
      const responseData = await response.json();

      if(responseData._id) {
        toast.success('La actualización de Referido se ha realizado correctamente.');
      } else {
        toast.error('Ha ocurrido un error en la operación.');
      }

    } catch(error) {
      toast.error('Ha ocurrido un error en la operación.');
    }
    navigate(endpoint);
  }

  return (
    <Page title="Editar Referido" breadcrumb={[{ label: labels.title, link: labels.link }, { label: labels.actions.new, link: '' }]}>
      <ReferralsForm
      values={values}
      setValues={setValues}
      isValid={isValid}
      enableInput={false}
      handleSubmit={handleSubmit}
      toast={toast}
      valuesError={valuesError}
      setIsValid={setIsValid}
      setValuesError={setValuesError}
      fileURL={fileURL} 
      loading={loading}
      setLoading={setLoading}/>
    </Page>
  );
}
