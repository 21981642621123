import PropTypes from "prop-types";
import "./avatar.css";

const Avatar = ({ id, cssClass, cssArticle, size='md', src, alt }) => {
  return (
    <section className={`__avatar ${cssClass} ${size}`}>
      <img id={id} name={id} src={src} alt={alt} />
      <article className={`${cssArticle}`} />
    </section>
  );
};


Avatar.propTypes = {
  /** Element id */
  id: PropTypes.string.isRequired,
  /** Avatar css classes. */
  cssClass: PropTypes.string,
  /** cssArticle element with contain css to notification */
  cssArticle: PropTypes.string,
  /** Size of image */
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  /** Source of image */
  src: PropTypes.string,
  /** Alt text to describe image */
  alt: PropTypes.string,
};

export default Avatar;
