
import { regex } from '@23people/moonbase-validators-v3';
import { useState, useEffect } from 'react';
import { BsPlusLg } from 'react-icons/bs';
import { FaTrashAlt } from 'react-icons/fa';
import Button from '../button';
import TextField from '../text-field';
import './multivalue.css';


export default function Multivalue(
  {
    label, value = [], 
    cssClass = '__multivalue', 
    id, 
    onChange=() =>{}, 
    onValidation = () => {},
    pattern = regex.TEXT
  }) {
  
  const [inputValue, setInputValue] = useState('');
  const [list, setList] = useState(value);
  const [showFirstRow, setShowFirstRow] = useState(false);
  const [valid, setValid] = useState(true);

  useEffect(() => {
    setList(value);
  }, [value]);

  const add = () => {

    if (!inputValue) {
      return;
    }

    const newList = new Set([...value, inputValue]); //remove repeated

    const newnewList = [...newList];

    const isValid = Array.isArray(newnewList);

    setInputValue('');
    setList(newnewList);
    onChange(newnewList);
    setValid(isValid);
    onValidation({ field: id, valid: isValid })
  }

  const remove = selected => {

    const newList = list.filter(el => el !== selected);
    setList(newList);
    onChange(newList);

    const isValid = Array.isArray(newList);
    setValid(isValid);
    onValidation({ field: id, valid: isValid })
  }

  const toggleFirstRow = () => {
    setShowFirstRow(!showFirstRow);
  }

  return <section className={cssClass}>
    
    <table id={id} className='divide-y divide-gray-200' width="448px">
      <thead>
        <tr><th width="288px">{label}</th><th width="32px"><BsPlusLg size={16} onClick={toggleFirstRow} /></th></tr>
      </thead>
      <tbody className='divide-y divide-gray-200'>
        {showFirstRow && <tr>
          <td className='first-row'>
            <TextField
              id='input'
              cssClass='__text-field __textbox-input'
              maxLength='100'
              pattern={pattern}
              value={inputValue}
              onChange={setInputValue}
              validationText="Por favor ingrese un nombre de categoría válido"
            />
          </td>
          <td className='first-row'>
            <Button
              id='btnAdd'
              cssClass='__button __circle-button'
              value={<BsPlusLg size={16} />}
              onClick={add}
            />
          </td>
        </tr>
        }
        
        {list.map(element => 
          <tr key={element}>
            <td>{element}</td>
            <td><Button
              id='btnAdd'
              cssClass='__button __remove-button'
              value={<FaTrashAlt size={14} />}
              onClick={() => remove(element)}
            /></td>
          </tr>
        )}
      </tbody>
      <tfoot />
    </table>
    
  </section>;
};