import { useLocation, useNavigate } from 'react-router-dom';
import GoogleLogin from '../../components/GoogleLoginButton';
import './login.css';
import useAuth from '../../hooks/useAuth';

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();

  const { setAuth } = useAuth();

  const from = location.state?.from?.pathname || "/";

  const onValidLogin = (result => {

    if (result.valid) {
      setAuth({ user: result.user });
      navigate(from, { replace: true });
    } else {
      console.error('Login no válido');
    }

  });

  return (
    <>
      <main className="login-main">
        <section className='login-card'>
          <img src="/img/luna-login.svg" alt="luna logo" />
          <img src="/img/luna-text.svg" height="55" alt="luna text" style={{ marginTop: "-180px" }} />
          <GoogleLogin clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID} onValidLogin={onValidLogin} />
        </section>
      </main>

    </>
  );
}