import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const ProtectedRoute = ({ allowedRoles }) => {
  const { auth } = useAuth();
  const location = useLocation();

  // if (!auth.user) {
  //   return <Navigate to="/login" state={{ from: location }} replace />;
  // }

  return (
    allowedRoles.includes(auth?.user?.role)
      ? <Outlet />
      : auth?.user
        ? <Navigate to="/unauthorized" state={{ from: location }} replace />
        : <Navigate to="/login" state={{ from: location }} replace />
  );
}

export default ProtectedRoute;