import { regex } from '@23people/moonbase-validators-v3';

import apiRequest from '../../lib/api-request';

import Button from '../../components/button';
import CardContainer from '../../components/card-container';
import TextField from '../../components/text-field';
import { Form } from '../../components/form';

import CVUploader from '../../components/cv-uploader.js/index.js';
import { Loader } from '../../components/loader';
import SelectRest from '../../components/select-rest';
import FlexLayout from '../../components/flex-layout';
import { api } from '../../api';
import './referrals.css';
import { emptyFn } from '../../lib/fn-utils';

export default function ReferralsForm({
  values,
  setValues,
  handleSubmit,
  isValid,
  enableInput,
  toast,
  valuesError,
  setValuesError,
  setIsValid,
  fileURL,
  setLoading,
  loading,
  handleEmailBlur=emptyFn
}) {

  const handleValidation = (e) => handleValues({...valuesError, [e.field]: e.valid});
  const handleValues = (val) => {
    setValuesError(val);
    setIsValid(Object.values(val).every((value) => value === true));
  }

  const handlePositionLevelChange = (id) => {
    setValues(v => ({ ...v, referredLevel: id}));
    handleValidation({field: 'referredLevel', valid: true});
  }

  const handleCVUpload = async (fileUrl, file) => {
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_API_URL}/cv`;
      const formData = new FormData();
      formData.append('file', file);

      const response = await apiRequest(url, {}, 'POST', formData, false);
      const data = await response.json();

      if(data.id) {
        setValues({...values, referredCvId: data.id});
        handleValues({...valuesError, referredCvId: true});
      }
    } catch(error) {
      toast.error('Ha ocurrido un error al subir el CV.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
        <Form>
              <CardContainer cssClass='__card-container __form __referred'>
                <FlexLayout>
                  <h2 className='__form-title'>Datos de Quien Refiere</h2>
                  <TextField
                    id='referrerEmail'
                    label='Email'
                    secondLabel="*"
                    maxLength='256'
                    required={true}
                    requiredText="El campo es requerido"
                    value={values.referrerEmail}
                    onChange={(e) => setValues(v => ({...v, referrerEmail: e }))}
                    onValidation={handleValidation}
                    onBlur={handleEmailBlur}
                    pattern={regex.EMAIL}
                    validationText="Por favor ingrese un email válido"
                    enabled={enableInput}
                  />
                  <TextField
                    id='referrerName'
                    label='Nombre'
                    secondLabel="*"
                    maxLength='60'
                    required={true}
                    requiredText="El campo es requerido"
                    value={values.referrerName}
                    onChange={(e) => setValues(v => ({...v, referrerName: e }))}
                    onValidation={handleValidation}
                    pattern={regex.TEXT}
                    validationText="Por favor ingrese un nombre válido"
                  />
                  <TextField
                    id='referrerPhone'
                    label='Telefono'
                    secondLabel="*"
                    maxLength='60'
                    required={true}
                    requiredText="El campo es requerido"
                    value={values.referrerPhone}
                    onChange={(e) => setValues(v => ({...v, referrerPhone: e }))}
                    onValidation={handleValidation}
                    pattern={regex.CHILEAN_TELEPHONE}
                    validationText="Por favor ingrese un teléfono válido"
                  />
                </FlexLayout>
              </CardContainer>
              <CardContainer cssClass='__card-container __form __referred'>
                <FlexLayout>
                  <h2 className='__form-title'>Datos del Referido</h2>
                  <TextField
                    id='referredName'
                    label='Nombre'
                    secondLabel="*"
                    maxLength='60'
                    required={true}
                    requiredText="El campo es requerido"
                    value={values.referredName}
                    onChange={(e) => setValues(v => ({...v, referredName: e }))}
                    onValidation={handleValidation}
                    pattern={regex.TEXT}
                    validationText="Por favor ingrese un Referido válido"
                  />

                  <TextField
                    id='referredPosition'
                    label='Posición'
                    secondLabel="*"
                    maxLength='60'
                    required={true}
                    requiredText="El campo es requerido"
                    value={values.referredPosition}
                    onChange={(e) => setValues(v => ({...v, referredPosition: e }))}
                    onValidation={handleValidation}
                    pattern={regex.TEXT}
                    validationText="Por favor ingrese un Referido válido"
                  />
                  <SelectRest
                    id='referredLevel'
                    label='Nivel de Experiencia'
                    required={true}
                    requiredText="El campo es requerido"
                    secondLabel='*'
                    placeholder='Seleccione un nivel de Experiencia'
                    value={values.referredLevel}
                    onChange={handlePositionLevelChange}
                    valueField='_id'
                    labelField='name'
                    endpoint={api.positionLevels}
                  />
                  <TextField
                    id='referredEmail'
                    label='Correo'
                    secondLabel="*"
                    maxLength='60'
                    required={true}
                    requiredText="El campo es requerido"
                    value={values.referredEmail}
                    onChange={(e) => setValues(v => ({...v, referredEmail: e }))}
                    onValidation={handleValidation}
                    pattern={regex.EMAIL}
                    validationText="Por favor ingrese un Referido válido"
                  />
                  <TextField
                    id='referredPhone'
                    label='Teléfono'
                    secondLabel="*"
                    maxLength='60'
                    required={true}
                    requiredText="El campo es requerido"
                    value={values.referredPhone}
                    onChange={(e) => setValues(v => ({...v, referredPhone: e }))}
                    onValidation={handleValidation}
                    pattern={regex.CHILEAN_TELEPHONE}
                    validationText="Por favor ingrese un Referido válido"
                  />
                  <TextField
                    id='referredLinkedin'
                    label='Linkedin'
                    secondLabel="*"
                    maxLength='60'
                    required={true}
                    requiredText="El campo es requerido"
                    value={values.referredLinkedin}
                    onChange={(e) => setValues(v => ({...v, referredLinkedin: e }))}
                    onValidation={handleValidation}
                    pattern={regex.URL}
                    validationText="Por favor ingrese un Referido válido"
                  />
                </FlexLayout>
                <section className="__cv-uploader-container">
                  <CVUploader onChange={handleCVUpload} fileURL={fileURL}></CVUploader>
                </section>
            </CardContainer>
          <CardContainer cssClass='__card-container __form __referred'>
            <footer>
              <Button
                id='btnGuardar'
                value='Guardar'
                onClick={handleSubmit}
                enabled={isValid} />
            </footer>
          </CardContainer>

        </Form>
      { loading && <Loader /> }
    </>
  );
}
