
import { useEffect, useRef, useState } from 'react';
import Button from '../button';
import './cv-uploader.css'


const defaultImg = '/img/background.svg';


const CVUploader = ({ id, cssClass = '__cv-uploader', label, secondLabel = '*', enabled = true, onChange = () =>{} , children, fileURL }) => {

  const [fileSrc, setFileSrc] = useState(defaultImg);

  useEffect(() => {
    if(!fileURL) return;

    setFileSrc(fileURL);

    return () => {
      URL.revokeObjectURL(fileSrc)
    };

  }, [fileSrc, fileURL]);

  const fileInput = useRef(null);

  const selectFile = () => {
    fileInput.current.click();
  }

  const handleFileChange = e => {

    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setFileSrc(objectUrl);
    onChange(objectUrl, e.target.files[0]);

  }

  return <section className={cssClass}>

    <header>
      <label htmlFor={id}>
        <span>{label}</span>
        <span>{secondLabel}</span>
      </label>
    </header>

    <main>
      <section>
        <input type="file" accept="application/pdf" onChange={handleFileChange} ref={fileInput} />
        <Button id="btnSeleccionar" value="Subir tu CV" onClick={selectFile} enabled={enabled} />
        { children }
      </section>
      <section>
        <embed
          src={fileSrc + "#toolbar=0"}
          type="application/pdf"
        />
      </section>
    </main>

  </section>
}

export default CVUploader;