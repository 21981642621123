import CardContainer from '../card-container';
import './kpi-card.css'

const KpiCard = ({ label, value, icon, link, id, cssClass = '__kpi-card' }) => {
  return <section className={cssClass}>
    <CardContainer>
      <main>
        <span>
          {icon}
        </span>

        <div>
          <label>{label}</label>
          <h1>{value}</h1>
        </div>

      </main>
      <footer>{link}</footer>
    </CardContainer>
  </section>
}

export default KpiCard;