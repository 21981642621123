import './header.css';

import Search from '../search';

export default function Header() {
  return <header className='__header'>

    <label className="toggle" htmlFor="sidebartoggler">☰</label>
    <Search id="search" placeholder="Búsqueda global" theme="inner-icon" />
  </header>;
};
