
import { toast } from 'react-toastify';
import { regex } from '@23people/moonbase-validators-v3';

import Button from '../../components/button';
import CardContainer from '../../components/card-container';
import Page from '../../components/Page';
import TextField from '../../components/text-field';
import { Form } from '../../components/form';

import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CVUploader from '../../components/cv-uploader.js/index.js';
import Select from '../../components/select';
import { useQuery } from '@tanstack/react-query';
import { Loader } from '../../components/loader';
import apiRequest from '../../lib/api-request';
import ApplicantApplyHystory from './list-apply-history';


export const fetchData = async (url) => {
    const result = await apiRequest(`${process.env.REACT_APP_API_URL}${url}`)

    if(!result.ok)
      throw new Error('Ha ocurrido un error');
    return result.json();
};

const initialValuesState = {
  name: '',
  lastname: '',
  email: '',
  phone: '',
  cvId: '',
  cvScore: '',
  referred: '',
  origin: {value:'', label:'Seleccione'},
  history: [],
}

const initialValueErrorState = {
  name: false,
  lastname: false,
  email: false,
  phone: false,
  cvId: false,
  cvScore: true,
  referred: true,
  origin: false,
}

const headers = {'Content-Type': 'application/json'};

export default function Apply({ endpoint, labels }) {
  const { id } = useParams();
  const {isLoading: originLoading, data: originData, isError: originError, } = useQuery(['/applicant-origin'], () => fetchData('/applicant-origin'))
  const {isLoading: requirementLoading, isError: requirementError, } = useQuery([`/requirements/${id}`], () => fetchData(`/requirements/${id}`))

  const [values, setValues] = useState(initialValuesState);
  const [valuesError, setValuesError] = useState(initialValueErrorState);

  const navigate = useNavigate();
  const [applicantId, setApplicantId] = useState('');
  const [loading, setLoading] = useState(false);
  const [enableInput, setEnableInput] = useState(true);
  const [isValid, setIsValid] = useState(false);

  if(originLoading || requirementLoading ) {
    return 'Cargando...';
  }
  if(originError || requirementError) {
    toast.error('Ha ocurrido un error');
    return;
  }

  const handleValidation = (e) => {
    handleValues({...valuesError, [e.field]: e.valid});
  };

  const handleValues = (val) => {
    setValuesError(val);
    setIsValid(Object.values(val).every((value) => value === true));
  }

  const handleEmailBlur = async (email) => {
    setLoading(true);
    if(!email) {
      setEnableInput(true);
      setApplicantId('');
      setLoading(false);
      return;
    }

    const url = `${process.env.REACT_APP_API_URL}/applicant/find?email=${encodeURIComponent(email)}&history=true`;
    let response;
    try {
      response = await apiRequest(url, {}, 'GET');
    } catch(error) {
      const { name, lastname, phone, history } = initialValuesState;
      setValues({...values, name, lastname, phone, history});
      handleValues({...valuesError, name: false, lastname: false, phone: false});
      setEnableInput(true);
      setApplicantId('');
      setLoading(false);
      return;
    }

    const data = await response.json()

    if ( response.ok && data._id) {
      const { _id, name, lastname, phone, history } = data;
      setValues({...values, name, lastname, phone, history});
      handleValues({...valuesError, name: true, lastname: true, phone: true});
      setApplicantId(_id);
      setEnableInput(false);
    } else {
      setEnableInput(true);
    }
    setLoading(false);
  }

  const handleCVUpload = async (fileUrl, file) => {
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_API_URL}/cv/score/${id}`;
      const formData = new FormData();
      formData.append('file', file);

      const response = await apiRequest(url, {}, 'POST', formData, false);
      const data = await response.json();

      if(data.id) {
        setValues({...values, cvId: data.id, cvScore: Number(data.score).toFixed(2)});
        handleValues({...valuesError, cvId: true, cvScore: true});
      }
    } catch(error) {
      toast.error('Ha ocurrido un error al subir el CV.');
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = async () => {

    if(!isValid) {
      toast.error('Por favor, revise los campos');
      return;
    }

    try {
      let newApplicantId;
      if(!applicantId) {
        const url = `${process.env.REACT_APP_API_URL}/applicant`;
        const body = {
          name: values.name,
          lastname: values.lastname,
          email: values.email,
          phone: values.phone
        };

        const response = await apiRequest(url, headers, 'POST', body);

        const data = await response.json();
        if(data._id) {
          newApplicantId = data._id;
        }
        else {
          toast.error('Ha ocurrido un error al crear la Postulación.');
          return;
        }
      }

      const url = `${process.env.REACT_APP_API_URL}/application`;
      const body = {
        applicant: newApplicantId || applicantId,
        requirement: id,
        cv: values.cvId,
        cvAccuracity: values.cvScore,
        origin: values.origin.value,
        referredBy: values.referred
      };

      const response = await apiRequest(url, headers, 'POST', body);
      const responseData = await response.json();

      if(responseData._id) {
        toast.success('La Postulación se ha creado correctamente.');
      } else {
        toast.error('Ha ocurrido un error al crear la Postulación.');
      }

    } catch(error) {
      toast.error('Ha ocurrido un error al crear la Postulación.');
    }
    navigate(endpoint);
  }

  return (
    <Page title="Ingresar Nuevo Postulante" breadcrumb={[{ label: labels.title, link: labels.link }, { label: labels.actions.apply, link: '' }]}>
      <CardContainer cssClass='__card-container __form __requirement_apply'>
        <Form>
          <main>
            <section className='__fields'>
              <TextField
                id='email'
                label='Email'
                secondLabel="*"
                maxLength='256'
                required={true}
                requiredText="El campo es requerido"
                value={values.email}
                onChange={(e) => setValues(v => ({...v, email: e}))}
                onBlur={handleEmailBlur}
                onValidation={handleValidation}
                pattern={regex.EMAIL}
                validationText="Por favor ingrese un email válido"
              />
              <TextField
                id='phone'
                label='Telefono'
                secondLabel="*"
                maxLength='60'
                required={true}
                requiredText="El campo es requerido"
                value={values.phone}
                onChange={(e) => setValues(v => ({...v, phone: e}))}
                onValidation={handleValidation}
                pattern={regex.CHILEAN_TELEPHONE}
                validationText="Por favor ingrese un teléfono válido"
                enabled={enableInput}
              />
              <TextField
                id='name'
                label='Nombre'
                secondLabel="*"
                maxLength='60'
                required={true}
                requiredText="El campo es requerido"
                value={values.name}
                onChange={(e) => setValues(v => ({...v, name: e}))}
                onValidation={handleValidation}
                pattern={regex.NAME}
                validationText="Por favor ingrese un nombre válido"
                enabled={enableInput}
              />
              <TextField
                id='lastname'
                label='Apellido'
                secondLabel="*"
                maxLength='60'
                required={true}
                requiredText="El campo es requerido"
                value={values.lastname}
                onChange={(e) => setValues(v => ({...v, lastname: e}))}
                onValidation={handleValidation}
                pattern={regex.SURNAME}
                validationText="Por favor ingrese un apellido válido"
                enabled={enableInput}
              />
              <TextField
                id='referred'
                label='Referido'
                maxLength='60'
                value={values.referred}
                onChange={(e) => setValues(v => ({...v, referred: e}))}
                onValidation={handleValidation}
                pattern={regex.SURNAME}
                validationText="Por favor ingrese un Referido válido"
              />
              <Select
                id='origin'
                label='Origen'
                secondLabel="*"
                required={true}
                requiredText="El campo es requerido"
                value={values.origin}
                onChange={(e) => {
                  setValues(v => ({...v, origin: e}))
                  handleValidation({field: 'origin', valid: true});
                }}
                options={originData.result.reduce((o,el) => [{value: el._id, label: el.name}, ...o],[])}
              />
            </section>
            <section className='__fields'>
              <ApplicantApplyHystory id='applicant-apply-history' label="Historial" data={values?.history} />
            </section>
            <section className="__cv-uploader-container">
              <CVUploader onChange={handleCVUpload}>
                <section>
                  <div className="__afinidad">
                    <label>Afinidad de CV</label>
                    <span>
                      { values.cvScore ? `${values.cvScore} %` : '-' }
                    </span>
                  </div>
                </section>
              </CVUploader>
            </section>
          </main>
          <footer>
            <Button
              id='btnGuardar'
              value='Guardar'
              onClick={handleSubmit}
              enabled={isValid} />
          </footer>

        </Form>
      </CardContainer>
      { loading && <Loader /> }
    </Page>
  );
}
